/* // -------------------------------------------------------------------------------------------------
// // foundation
---------------------------------------------------------------------------------------------------- */

/* //.	- base structure
---------------------------------------------------------------------------------------------------- */

html {
	font-size: 62.5%; /* 10px */
	overflow-y: scroll;
}

body {
	color: $fc-base;
	font-size: $fs-base;
	font-family: $ff-sans-serif;
}
.page {
	position: relative;	
}

/* //.	- inline elements
---------------------------------------------------------------------------------------------------- */

a {}

a,
a * {}

a:link,
a:visited {}

a:link {
	color: $fc-link;
	text-decoration: underline;
}

a:visited {
	color: #395c9a;
}

a:hover {
	color: $fc-hover;
	text-decoration: underline;
}

a:active {
	color: #e60012;
}

em {
	font-style: normal;
}

strong,
strong * {
	font-weight: bold;
}

strong {}

del {
	display: inline;
	text-decoration: line-through;
}

img {
	display: inline-block;
	text-align: center;
}

a img {
	vertical-align: top;
}




/* //.	- common classes
---------------------------------------------------------------------------------------------------- */

.al-right {
	text-align: right;
}
.al-left {
	text-align: left;
}
article#page-content.special {
	max-width: 610px;
	width: 100%;
	margin: 0 auto;
}
article#page-content.special .page-content-body__inner{
	@media(max-width: $w-base) {
		@mixin sp-base;
		img {
			width: 100%;
		}
	}
}
article#page-content.special nav.work-nav ul li {
    margin-bottom: 15px;
}



/* //..	[class] Attention Marker / strong.attention
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

article#page-content strong.attention {
	display: inline-block;
	margin-right: .25rem;
	padding: 0 4px;
    border-radius: 2px;
	font-weight: normal;
	color: white;
	text-transform: uppercase;
	background-color: rgb(153,153,153);
}

	article#page-content strong.attention_new,
	article#page-content strong.attention_now {
		background-color: rgb(230,0,18);
	}

	article#page-content strong.attention_extra {
		background-color: rgb(51,153,255);
	}

	article#page-content strong.attention_members {
		background-color: rgb(255,182,0);
	}

	article#page-content strong.attention_expired {
		background-color: rgb(153,153,153);
	}

	article#page-content strong.attention_now,
	article#page-content strong.attention_expired,
	article#page-content strong.attention_members,
	article#page-content strong.attention_extra {
		min-width: 4rem;
		text-align: center;
	}


/* //..	[class] Provisions / ul.provisions
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
article#page-content ul.provisions {
	margin-bottom: 30px;
}
article#page-content ul.provisions li {
	list-style: none outside;
	position: relative;
	margin-left: 0;
	padding-left: 2.5rem;
}

	article#page-content ul.provisions li span.order-marker {
		position: absolute;
		left: 0;
	}


/* //..	[sole] Page Sharing / menu#page-sharing
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

article#page-content menu#page-sharing {
	clear: both;
	overflow: hidden;
	margin: 20px 0 60px;
}
article.home#page-content footer#page-content-footer menu#page-sharing {
	margin-bottom: 0;
}
article.work-front-page#page-content header#page-content-header menu#page-sharing {
	margin: -5px 0 20px;
}

	article#page-content menu#page-sharing li {
		float: left;
		position: relative;
		margin: 0;
		margin-right: 5px;
		min-height: 18px;
	}

		article#page-content menu#page-sharing li img {
			display: block;
		}


/* //..	[class] Page Flipper / nav.page-flipper
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

article#page-content nav.page-flipper {
	margin-top: 25px;
	padding: 0 15px;
}

	article#page-content nav.page-flipper p {
		margin-bottom: 0;
		text-align: center;
		font-size: 1.3rem;
	}

	article#page-content nav.page-flipper ul {
		overflow: hidden;
		margin: -2.6em auto 0;
		width: 8rem;
		text-align: center;
	}
	
		article#page-content nav.page-flipper ul li {
			list-style-type: none;
			margin: 0;
		}
		article#page-content nav.page-flipper ul li.page-flipper_prev {
			float: left;
		}
		article#page-content nav.page-flipper ul li.page-flipper_next {
			list-style-type: none;
			float: right;
		}
	
			article#page-content nav.page-flipper ul li a {
				font-size: 1.5rem;
			}


/* //.	- section
---------------------------------------------------------------------------------------------------- */


/* //..	[elemental] article#page-content * section
/* //..	[elemental] article#page-content * nav
/* //..	[elemental] article#page-content * article.entry
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

article#page-content * section,
article#page-content * nav,
article#page-content * article.entry {}

	article#page-content * section header,
	article#page-content * nav header,
	article#page-content * article.entry header {
		position: relative;
		overflow: hidden;
		padding: .2em 60px 0 15px;
		min-height: 21px;
		-webkit-border-radius: 6px 6px 0 0;
		   -moz-border-radius: 6px 6px 0 0;
		    -ms-border-radius: 6px 6px 0 0;
		     -o-border-radius: 6px 6px 0 0;
		        border-radius: 6px 6px 0 0;
		color: white;
		background-color: black;
	}

	article#page-content * section section header,
	article#page-content * section article header,
	article#page-content * article section header,
	article#page-content * section article.entry header {
		padding: 0;
		min-height: auto;
		-webkit-border-radius: 0;
		   -moz-border-radius: 0;
		    -ms-border-radius: 0;
		     -o-border-radius: 0;
		        border-radius: 0;
		color: black;
		background-color: transparent;
	}

		article#page-content * section header h2,
		article#page-content * nav header h2,
		article#page-content * article.entry header h2 {
			line-height: 1.4;
			font-size: 1.4rem;
			font-weight: bold;
		}

			article#page-content * section header h2 a,
			article#page-content * nav header h2 a {
				font-weight: bold;
				color: white;
				text-decoration: none;
			}

		article#page-content * section header nav.catalog-nav,
		article#page-content * article.entry header nav.catalog-nav {
			position: absolute;
			right: 10px;
			top: .2rem;
		}

	article#page-content * section div.section-body,
	article#page-content * nav div.section-body,
	article#page-content * article.entry div.entry-body {
		padding: 10px 0 0;
	}

		article#page-content * section div.section-body section,
		article#page-content * nav div.section-body section,
		article#page-content * article.entry div.entry-body section {
		}

			article#page-content * section div.section-body section h3,
			article#page-content * section div.section-body article h3,
			article#page-content * nav div.section-body section h3,
			article#page-content * article.entry div.entry-body section h3 {
				margin-bottom: .5rem;
				font-size: 1.4rem;
				font-weight: bold;
			}