/* // -------------------------------------------------------------------------------------------------
// // editors
---------------------------------------------------------------------------------------------------- */


/* //..	.entry__itemlist
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
.entry__itemlist {
	margin-bottom: 24px;
	@media(max-width: $w-base) {
		@mixin sp-base;
	}
}
.page-content-main .entry__itemlist {
	@media(min-width: $w-breakpoint) and (max-width: $w-base) {
		padding-right: 0;
	}
}
.page-content-aside .entry__itemlist{
	@media(min-width: $w-breakpoint) and (max-width: $w-base) {
		padding-left: 0;
	}
}
.entry__item {
	width: 100%;
	display: flex;
	border-bottom: 1px solid $cl-lightgray;
	padding: 8px 0;
}
.entry__image {
	display: inline-block;
	padding-right: 16px;
	a {
		width: 72px;
		height: 72px;
		display: block;
		overflow: hidden;
		&:hover {
			@mixin hover-image;
		}
		@media(max-width: $w-breakpoint) {
			width: 84px;
			height: 84px;
		}
	}
	img {
		width: 100%;
		height: auto;
	}
}
.entry__text {
	-webkit-flex:1;
}
.entry__date {
	color: $fc-gray;
	font-size: 11px;
	margin-bottom: 8px;
}
.entry__title {
	margin-bottom: 8px;
	a {
		font-size: $fs-base;
		@mixin lineHeight 16,22;
		text-decoration: none;
		color: $fc-base;
		font-weight: bold;
		&:hover {
			color: $fc-hover;
			transition: 0.2s;
		}
	}
}
.entry__data li {
	list-style: none;
	display: inline-block;
	font-size: 1.1rem;
	a {
		font-size: 1.1rem;
	}
}


/* //..	.thumentry__itemlist
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
a.thumentry__itemlink {
	display: block;
	color: $fc-base;
	text-decoration: none;
	border-bottom: 1px solid $cl-lightgray;
	&:hover {
		color: $fc-hover;
		@mixin hover-image;
	}
}
.thumentry__item {
	width: 100%;
	border-bottom: none;
	display: flex;
	padding: 8px 0;

	.entry__image {
		width: 72px;
		height: 72px;
		overflow: hidden;
		img {
			width: 100%;
			height: auto;
		}
	}
	.entry__title {
		font-size: $fs-base;
		@mixin lineHeight 16,22;
		font-weight: bold;
		margin-bottom: 8px;
	}
}





/* //..	.pagination
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
.pagination {
	text-align: center;
	margin-bottom: 64px;
	display: flex;
	justify-content: center;
	@media(max-width: $w-breakpoint) {
		margin-bottom: 30px;
	}
}
.pagination__link {
	display: inline-block;
	width: 24px;
	height: 24px;
	background-color: #cccccc;
	border-radius: 4px;
	text-align: center;
	box-sizing: border-box;
	@media(max-width: $w-breakpoint) {
		width: 30px;
		height: 30px;
	}

	span{
		width: 16px;
		height: 16px;
		vertical-align: middle;
		@media(max-width: $w-breakpoint) {
			margin-top: 4px;
		}
	}
	a {	display: block;	}
}
.pagination__link--prev {
	a {
		padding-right: 4px;
	}
	span{
		@mixin arrow-left 8px, 1px, #fff;
	}
}
.pagination__link--next {
	a {
		padding-left: 4px;
	}
	span{
		@mixin arrow-right 8px, 1px, #fff;
	}
}

.pagination__pagenumlist {
	display: inline-block;
	padding: 0 16px;
	margin: 0;
	@media(max-width: $w-breakpoint) {
		padding: 0 30px;
	}
}
.pagination__pagenum {
	display: inline-block;
	vertical-align: top;
	height: 24px;
	line-height: 24px;
	@media(max-width: $w-breakpoint) {
		height: 30px;
		line-height: 30px;
	}
}


/* //..	.editors__itemlist
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
.editors__itemlist {
	display: flex;
	flex-wrap: wrap;
	@media(max-width: $w-base) {
		@mixin sp-base;
	}
}
.editors__item {
	@mixin col-st 3,3n;
	padding: 24px;
	margin-bottom: 24px;
	background-color: #f3f3f3;
	border-radius: 8px;
	box-sizing: border-box;

	@media(max-width: $w-breakpoint) {
		width: 100%;
		margin-right: 0;
	}
}
.editors__item__header {
	display: flex;
	border-bottom: 1px solid #cccccc;
	padding-bottom: 16px;
	margin-bottom: 8px;
}
.editors__image {
	display: inline-block;
	img {
		width: 72px;
		height: 72px;
		margin-right: 16px;
	}
	@media(max-width: $w-breakpoint) {
		img {
			width: 80px;
			height: 80px;
		}
	}
}
.editors_text {
	-webkit-flex:1;
}
.editors__lead {
	font-size: 1.4rem;
	font-weight: bold;
	@mixin lineHeight 14,20;
	margin-bottom: 8px;
}
.editors__name {
	font-size: $fs-h2;
	font-weight: bold;
	line-height: 1.4;

	@media(max-width: $w-breakpoint) {
		font-size: $fs-sp-h2;
	}
}
.editors__body {
	font-size: 1.4rem;
}
.editors__twitter {
	display: block;
	font-size: 1.4rem;
	@media(max-width: $w-breakpoint) {
		font-size:1.2rem;
	}
}

/* // -------------------------------------------------------------------------------------------------
// // editors blog
---------------------------------------------------------------------------------------------------- */


/* //..	.blogentry
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

.blogentry {
	@media(max-width: $w-breakpoint) {
		@mixin sp-base;
	}
}
.blogentry__heading{
	margin-bottom: 32px;
	@media(max-width: $w-breakpoint) {
		margin-bottom:20px;
	}
}
.blogentry__heading__date {
	color: $fc-gray;
	font-size: 11px;
	margin-bottom: 8px;
}
.blogentry__heading__title {
	color: $fc-base;
	font-weight: bold;
	font-size: 2.4rem;
	@mixin lineHeight 24,30;
	border-bottom: 1px solid #cccccc;
	padding-bottom: 16px;

	@media(max-width: $w-base) {
		font-size: 1.8rem;
		@mixin lineHeight 18,23;
		padding-bottom: 8px;
	}
}
.blogentry__headinginfo {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 40px;
	background-color: #f3f3f3;
	padding: 0 16px;
	@media(max-width: $w-breakpoint) {
		display: block;
		height: auto;
		padding: 10px 8px;
	}
}
.blogentry__heading__socialbuttons {
	display: flex;
	@media(max-width: $w-breakpoint) {
		width: 100%;
		margin-bottom: 10px;
	}
}
.blogentry__heading__socialbutton {
	display: inline-block;
	margin-right: 5px;
}
.blogentry__heading__data {
	font-size: 1.1rem;
	text-align: right;
	@media(max-width: $w-breakpoint) {
		width: 100%;
		text-align: left;
	}
}
.blogentry__heading__dataitem {
	display: inline-block;
}

.blogentry__body {
	margin-bottom: 48px;
	p {
		font-size: $fs-base;
		margin-bottom: 30px;
	}
	img {
		@media(max-width: $w-breakpoint) {
			max-width: 100%;
			height: auto;
		}
	}
}
.blogentry__footer {
	padding-bottom: 24px;
}

.blogentry__author {
	width: 100%;
	background-color: #f3f3f3;
	padding: 24px;
	box-sizing: border-box;
	border-radius: 8px;

	@media(max-width: $w-breakpoint) {
		padding: 15px;
	}
}
.blogentry__author__header {
	display: flex;
	border-bottom: 1px solid #cccccc;
	padding-bottom: 8px;
	margin-bottom: 8px;

	@media(max-width: $w-breakpoint) {
		padding-bottom: 15px;
	}
}
.blogentry__author__body {
	font-size: 1.4rem;
	margin-bottom: 24px;
}
.blogentry__author__image {
	img {
		display: inline-block;
		height: 72px;
		margin-right: 16px;
	}
}
.blogentry__author__text {
	-webkit-flex:1;
}
.blogentry__author__lead {
	font-size: 1.4rem;
	font-weight: bold;
	@mixin lineHeight 14,18;

	@media(max-width: $w-breakpoint) {
		margin-bottom: 8px;
	}
}
.blogentry__author__name {
	font-size: 2.4rem;
	font-weight: bold;
	@media(max-width: $w-breakpoint) {
		font-size: 1.8rem;
	}
}
.blogentry__author__twitter {
	font-size: 14px;
	margin-left: 1.1rem;

	@media(max-width: $w-breakpoint) {
		font-size: 1rem;
	}
}
.blogentry__author__text {
	margin-bottom: 24px;

	@media(max-width: $w-breakpoint) {
		margin-bottom: 0;
	}
}
.blogentry__author__widget {
	iframe {
		width: 100%;		
	}
}






