/* // -------------------------------------------------------------------------------------------------
// // div#page-content-body
---------------------------------------------------------------------------------------------------- */



/* // -------------------------------------------------------------------------------------------------
// // .top-content-body
---------------------------------------------------------------------------------------------------- */
.top-content-body {
	display: flex;
	width: 100%;
	max-width: 1080px;
	@media(max-width: $w-breakpoint) {
		display: block;
	}
}
.top-content-main {
	width: calc(804/1080*100)%;
	margin-right: calc(24/1080*100)%;
	@media(max-width: $w-base) {
		padding-left: 15px;
		box-sizing: border-box;
	}
	@media(max-width: $w-breakpoint) {
		width: 100%;
		padding-left: 0;
		margin-right: 0;
	}
	.top-content-heading {
		@media(max-width: $w-base) {
			padding: 0;
		}
		@media(max-width: $w-breakpoint) {
			padding: 0 15px;
		}
	}
	
}
.top-content-aside {
	width: calc(252/1080*100)%;
	@media(max-width: $w-base) {
		padding-right: 15px;
		box-sizing: border-box;
	}
	@media(max-width: $w-breakpoint) {
		width: 100%;
		padding-right: 0;
		@mixin sp-base;
	}

}


/* //..	[Home]  .top-content-heading
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

.top-content-heading {
	width: 100%;
	max-width: $w-base;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin: 0 auto 24px;
	@media(max-width: $w-base) {
		@mixin sp-base;
	}
}

.top-heading--1 {
	color: $fc-szsred;
	font-weight: bold;
	font-size: $fs-h1;
	@mixin lineHeight 32,32;
	
	@media(max-width: $w-base) {
		font-size: $fs-sp-h1;
		@mixin lineHeight 24,28;
	}
}

.top-heading__viewall {
	width: 120px;
	height: 24px;
	text-align: center;
	background-color: $cl-red;
	border-radius: 6px;
	&:hover {
		background: color($cl-red l(55%));
		transition: 0.2s;
	}
	a {
		text-decoration: none;
		line-height: 24px;
		font-size: 1.2rem;
		color: $fc-white;
		padding-left: 14px;
		@mixin arrow-right 6px, 1px, #fff;
	}
	@media(max-width: $w-breakpoint) {
		display: none;
	}
}


/* //..	[Home]  .top-announce
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
.top-announce {
	padding: 8px;
	@media(max-width: $w-breakpoint) {
		padding: 8px 15px 10px;
	}
}
.top-announce__inner {
	width: 100%;
	background-color: #ffffff;
	max-width: 1080px;
	padding: 3px;
	margin: 0 auto;
	box-sizing: border-box;
	border-radius: 4px;
}
.top-announce__heading {
	width: 136px;
	height: 60px;
	display: table-cell;
	color: #ffffff;
	text-align: center;
	vertical-align: middle;
	border-radius: 4px;
	@media(max-width: $w-breakpoint) {
		display: block;
		width: 100%;
		height: 30px;
		margin-bottom: 8px;
		box-sizing: border-box;
	}
}
.top-announce--emmergent {
	background-color: #ffcce0;
	.top-announce__heading {
		background-color: #ff0000;
	}
	.top-announce__title {
		background: url(/res/img/common/page-top/page-content/announce/icn_emmergent.png) no-repeat;
		background-size: 24px 24px;
		@media(max-width: $w-breakpoint) {
			background-size: 18px 18px;
			background-position: left center;
		}
	}
}
.top-announce--special {
	background-color: #ffd940;
	.top-announce__heading {
		background-color: $cl-red;
	}
	.top-announce__title {
		background: url(/res/img/common/page-top/page-content/announce/icn_special.png) no-repeat;
		background-size: 24px 24px;
		@media(max-width: $w-breakpoint) {
			background-size: 18px 18px;
			background-position: left center;
		}
	}
}
.top-announce__title {
	display: inline-block;
	padding-left: 32px;
	@media(max-width: $w-breakpoint) {
		line-height: 30px;
		padding-left: 26px;
	}
}
.top-announce__body {
	display: table-cell;
	padding: 8px 16px;
	@media(max-width: $w-breakpoint) {
		display: block;
		width: 100%;
		padding: 0 7px 5px;
		box-sizing: border-box;
	}
}

/* //..	[Home]  .top-hero
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
.top-hero {
	width: 100%;
	background-color: #eeeeee;
}
.top-hero__inner {
	max-width: $w-base;
	margin: 0 auto 48px;
	img {
		width: 100%;
		height: auto;
	}
}
.slick-dots {
	bottom: -30px;
}
.slick-slide:hover {
	@mixin hover-image;
}
.slick-dots li button:before {
	font-size: 10px;
}
.slick-dots li.slick-active button:before {
	color: $cl-red!important;
}
.slick-dots li.slick-active button:hover {
	color: $cl-red!important;
}

/* //..	[Home]  .entry__itemlist--top
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
.thumentry__itemlist--top {
	display: flex;
	flex-wrap: wrap;

	a.thumentry__itemlink {
		@mixin col-st 2,2n;
	}

	@media(max-width: $w-base) {
		@mixin sp-base;
	}
	@media(max-width: $w-breakpoint) {
		a.thumentry__itemlink {
			width: 100%;
			display: block;
		}
	}
}


/* //..	[Home]  .pickup__itemlist
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

.pickup__itemlist {
	display: flex;
	flex-wrap: wrap;

	@media(max-width: $w-breakpoint) {
		@mixin sp-base;
		margin-bottom: 45px;
	}
}
.pickup__navlist {
	text-align: right;
	font-size: 0;

	@media(max-width: 920px) {
		display: none;
	}
}
.pickup__navlist__item {
	display: inline-block;
	width: 124px;
	height: 24px;
	border-radius: 4px;
	text-align: center;
	margin-left: 4px;
	a {
		display: block;
		text-decoration: none;
		line-height: 24px;
		color: $fc-white;
		font-size: 1.2rem;
		padding-left: 8px;
		@mixin arrow-right 4px, 1px ,#fff;
	}
}
.pickup__navlist__item--fictions {
	background-color: #0062b4;
	&:hover {
		background: color(#0062b4 l(46%));
		transition: 0.2s;
	}
}
.pickup__navlist__item--comics {
	background-color: #009270;
	&:hover {
		background: color(#009270 l(36%));
		transition: 0.2s;
	}
}
.pickup__navlist__item--special {
	background-color: #44578e;
	&:hover {
		background: color(#44578e l(50%));
		transition: 0.2s;
	}
}
.pickup__navlist__item--sa {
	background-color: #0679be;
	&:hover {
		background: color(#0679be l(46%));
		transition: 0.2s;
	}
}
.pickup__item {
	@media(min-width: $w-breakpoint) {
		@mixin col-st 3,3n;
		margin-bottom: 24px;
	}
	@media(max-width: $w-breakpoint) {
		@mixin col-st 2,2n;
		margin-bottom: 10px;
	}
}
a.pickup__link {
	display: block;
	color: $fc-base;
	text-decoration: none;
	&:hover {
		@mixin hover-image;
		color: $fc-hover;
	}
}
.pickup__title {
	color: $fc-base;
	text-decoration: none;
	margin-bottom: 8px;
	img {
		width: 100%;
		height: auto;
	}
}
.pickup__info {
	margin-bottom: 16px;
}
.pickup__labels {
	display: flex;
	justify-content: space-between;
	margin-bottom: 16px;
}
.pickup__label {
	width: 120px;
	height: 16px;
	line-height: 16px;
	font-size: 1.1rem;
	border-radius: 2px;
	color: $fc-white;
	text-align: center;
}
.pickup__label--fictions {
	background-color: #0062b4;
}
.pickup__label--comics {
	background-color: #009270;
}
.pickup__label--special {
	background-color: #44578e;
}
.pickup__label--sa {
	background-color: #0679be;
}
.pickup__label--awards {
	background-color: #e30920;
}
.pickup__label--new {
	width: 40px;
	background-color: #ff0000;
	border-radius: none;
	@media(max-width: $w-breakpoint) {
		display: none;
	}
}
.pickup__text {
	font-size: 1.4rem;
	font-weight: bold;
	@mixin lineHeight 14, 20;
}

/* //..	[Home]  .top-twi4
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
.top-twi4 {
	margin-bottom: 16px;
	img:hover {
		@mixin hover-image;
	}
}
.top-twi4__header {
	background-color: #00a0e9;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 16px;
	border-radius: 10px 10px 0 0; 
}
.top-twi4__heading {
	line-height: 1;
}
.top-twi4__viewall {
	width: 98px;
	font-size: 1.1rem;
	text-align: center;
	border: 1px solid #fff;
	border-radius: 4px;
	&:hover {
		cursor: pointer;
		background: #fff;
		transition: 0.2s;
		a {
			color: #00a0e9;
			@mixin arrow-right 6px, 1px, #00a0e9;
			transition: 0.2s;
		}
	}
	a {
		display: block;
		text-decoration: none;
		color: $fc-white;
		@mixin arrow-right 6px, 1px, #fff;
		padding-left: 10px;
	}
}

.top-twi4__body {
	border: 2px solid $cl-lightgray;
	border-bottom: none;
	position: relative;
	padding-top: calc(100% * (1.496/1));

	@media(max-width: $w-breakpoint) {
		display: block;
		max-height: 100%;
	}
	img {
		display: none;
		width: 100%;
		height: auto;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
		width: auto;
		height: auto;
		max-width: 100%;
		max-height: 100%;
	}
}
.top-twi4__footer {
	border: 2px solid $cl-lightgray;
	border-top: none;
	padding: 0 15px 15px;
	border-radius: 0 0 10px 10px; 
}
.top-twi4__followtext {
	text-align: center;
	margin-bottom: 16px;
	img {
		width: 80%;
		height: auto;
	}
}

.top-twi4__followbutton {
	display: block;
	width: 100%;
	height: 40px;
	line-height: 40px;
	text-align: center;
	background-color: #00a0e9;
	padding: 0 15px;
	box-sizing: border-box;
	margin-bottom: 16px;
	border-radius: 4px;
	&:hover {
		background: color(#00a0e9 l(60%));
		transition: 0.2s;
	}
	a {
		display: block;
		text-decoration: none;
		color: #fff;
		font-size: 1.4rem;
	}
}
.top-twi4__banner {
	img {
		width: 100%;
		height: auto;
	}
}



/* //..	[Home]  .top-aside-banner
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
.top-aside-banner {
	width: 100%;
	margin-bottom: 16px;
	img {
		width: 100%;
		height: auto;
	}
	a {
		display: block;
		&:hover {
			@mixin hover-image;
		}
	}
}

/* //..	[Home]  .top-editors
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
.editors-top {
	width: 100%;
	background: #f5f5f5 url(/content/top/bg_blog.png) no-repeat center top;
	background-size: cover;
	padding: 40px 0;
	@media(max-width: $w-breakpoint) {
		background-image : none;
	}
	.entry__itemlist,
	.editors-top__twitter {
		@media(min-width: $w-breakpoint) {
			@mixin col-st 2,2n;
			display: table-cell;
			vertical-align: top;
		}
		@media(max-width: $w-breakpoint) {
			width: 100%;
			display: block;
		}
	}
	.entry__itemlist {
		margin-bottom: 0;
		@media(max-width: $w-breakpoint) {
			padding: 0;
			margin-bottom: 15px;
		}
	}
}

.editors-top__inner {
	height: 100%;
	display: flex;
	max-width: $w-base;
	margin: 0 auto 40px;
	@media(max-width: $w-breakpoint) {
		display: block;
		@mixin sp-base;
		margin-bottom: 30px;
	}
}
.editors-top__twitter {
	@media(max-width: $w-base) {
		padding-right: 15px;
		box-sizing: border-box;
	}
	@media(max-width: $w-breakpoint) {
		@mixin sp-base;
		height: 400px;
	}
}

.editors-top__twitter-inner {
	height: 100%;
	iframe {
		border: 1px solid #e6eaed!important;
		height: 100%!important;
		@media(max-width: $w-breakpoint) {
			height: 400px!important;
			min-height: 292px!important;
		}
	}
}

/* //..	[Home]  .banner-top
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

.banner-top {
	background-color: $cl-red;
	padding: 40px 0;
	margin-bottom: 40px;

	@media(max-width: $w-breakpoint) {
		padding: 22px 0;
	}
}
.banner-top__itemlist {
	width: 100%;
	max-width: $w-base;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;

	@media(max-width: $w-base) {
		@mixin sp-base;
	}

	@media(max-width: $w-breakpoint) {
		display: block;
	}
}
.banner-top__item {
	margin-right: calc(24/1080*100)%;
	&:last-child {
		margin-right: 0;
	}
	a {
		display: block;
		&:hover {
			@mixin hover-image;
		}
	}
	img {
		width: 100%;
		height: auto;
	}
	@media(max-width: $w-breakpoint) {
		width: 100%;
		margin-bottom: 15px;
		&:last-child {
			margin-bottom: 0;
		}
	}
}


/* //..	[Home]  .book-entry__comment--top
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
.book__comment__text {
	font-size: 1.4rem;
	margin-bottom: 8px;
}