/* // -------------------------------------------------------------------------------------------------
// // header
---------------------------------------------------------------------------------------------------- */


/* //.	- basics
---------------------------------------------------------------------------------------------------- */

.page-header {
	margin: 0 auto;
	width: 100%;
	border-bottom: 5px solid $cl-red;
	background: url(/res/img/common/page-top/page-header/bg_header_left.png) left top no-repeat,url(/res/img/common/page-top/page-header/bg_header_right.png) right top no-repeat;

	@media(max-width: 1079px){
		background-image: none;
		border-bottom: none;
		width: 100%;
		position: fixed;
		top: 0px;
		left: 0;
		z-index: 100;
	}
}
.page-header.is-open {
	@media(max-width: 1079px){
		height: 100%;
		background-color: #333333;
	}
	.page-header__inner {
		@media(max-width: 1079px){
			border-bottom: 5px solid #666666;
		}
	}
}
.page-header__inner {
	width: 100%;
	max-width: $w-base;
	height: 112px;
	margin: 0 auto;
	position: relative;

	@media(max-width: 1079px){
		height: 70px;
		background-color: $cl-white;
		border-bottom: 5px solid $cl-red;
		box-sizing: border-box;
	}
}


/* //.	.site-id
---------------------------------------------------------------------------------------------------- */

.site-id {
	@media(min-width: $w-base){
		display: inline-block;
		position: absolute;
		top: 12px;
		left: 50%;
		margin-left: -89px;
	}
	@media(max-width: 1079px){
		position: inherit;
		display: table;
		height: 100%;
		text-align: center;
		margin:  auto;
	}
}

.siteid__image {
	@media(max-width: 1079px){
		display: table-cell;
		vertical-align: middle;
		width: 100px;
		height: 50px;
		img {
			width: 100%;
			height: auto;
		}
	}
	a {
		display: block;
		&:hover {
			@mixin hover-image;
		}
	}
}




/* //.	.header-menu
---------------------------------------------------------------------------------------------------- */
.header-menu {
	@media(min-width: $w-base){
		height: 112px;
		display: flex;
	}
	@media(max-width: 1079px){
		display: none;
	}
}
.header-menu__itemlist {
	@media(min-width: $w-base){
		width: 50%;
		display: flex;
		align-items: flex-end;
		&:first-child{
			justify-content: flex-end;
			padding-right: 228px;
		}
	}
}
.header-menu__item {
	@media(min-width: $w-base){
		padding: 0 24px 5px;
		a {
			color: $fc-szsred;
			text-decoration: none;
			font-size: 18px;
			font-weight: bold;
			&:hover {
				color: $fc-hover;
				transition: 0.2s;
			}
		}
	}
}
.header-menu--sp {
	@media(min-width: $w-base){
		display: none;
	}
	@media(max-width: 1079px){
		display: block;
		width: 100vw;
		height: 100vh;
		position: absolute;
		top: 70px;
		left: -150%;
		background-color: #333333;
		padding: 0 15px;
		box-sizing: border-box;
		z-index: 100;
		transition: 0.3s ease-in-out;
	}
	.header-menu__itemlist--sp {
		display: flex;
	}
	.header-menu__item {
		width: 100%;
		border-bottom: 2px solid #666666;
		a {
			display: block;
			text-decoration: none;
			min-height: 60px;
			line-height: 60px;
			color: $fc-white;
			font-size: 1.8rem;
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	.header-menu__item--sp {
		display: inline-block;
		width: 50%;
		height: 44px;
		text-align: center;
		border-bottom: none;
		margin: 8px 0;
		&:first-child {
			border-right: 1px solid #666666;
		}
		a {
			line-height: 44px;
			padding-left: 8px;
			padding-right: 8px;
		}
	}
	.header-menu__item--twi4 {
		border-bottom: none;
		text-align: center;
		padding: 10px 0;
		img {
			vertical-align: middle;
		}
	}
}
.header-menu--sp.is-open {
	@media(max-width: 1079px){
		display: block;
		left: 0;
		transition: 0.3s ease-in-out;
	}
}




/* //.	.header-menu__button
---------------------------------------------------------------------------------------------------- */
.header-menu__button {
	@media(min-width: $w-base){
		display: none;
	}
	@media(max-width: 1079px){
		display: block;
		box-sizing: border-box;
		position: absolute;
		top: 0;
		left: 0;
		&:hover {
			cursor: pointer;
		}
	}
}
.header-menu__icon {
	display: block;
	width: 47px;
	height: 65px;
	background: url('/res/img/common/page-top/page-header/menu/btn_open.png') no-repeat left top;
	background-size: 100% auto;
	position: relative;
	transition: 0.2s ease-in-out;
}
.header-menu__icon:before {
	display: none;
	content: " ";
	width: 47px;
	height: 65px;
	background: url('/res/img/common/page-top/page-header/menu/btn_close.png') no-repeat left top;
	background-size: 100% auto;
	position: absolute;
	left: -120%;
	top: 0;
	transition: 0.4s ease-in-out;
	z-index: 100;
}
.page-header.is-open {
	.header-menu__icon:before {
		display: block;
		left: 0;
		transition: 0.4s ease-in-out;
	}
}

/* //.	.header-submenu
---------------------------------------------------------------------------------------------------- */

.header-submenu__itemlist {
	display: block;
	font-size: 0;
	position: absolute;
	top: 8px;
	right: 110px;

	@media(max-width: 1079px){
		display: none;
	}
}
.header-submenu__item {
	display: inline-block;
	a {
		font-size: 1.2rem;
		color: #666666;
		text-decoration: none;
		padding: 0 12px;
		margin-left: 12px;
		@mixin arrow-right 4px, 1px, #666;
		&:hover {
			color: $fc-hover;
			transition: 0.2s;
			@mixin arrow-right 4px, 1px, $fc-hover;
		}
	}
}


/* //.	.link-twi4
---------------------------------------------------------------------------------------------------- */
.link-twi4 {
	display: inline-block;
	position: absolute;
	top: 8px;
	left: 0;
	&:hover {
		@mixin hover-image;
	}

	@media(max-width: 1079px){
		display: none;
	}
}


/* //.	.link-seikaisha
---------------------------------------------------------------------------------------------------- */
.link-seikaisha {
	display: inline-block;
	position: absolute;
	top: 32px;
	right: 0;
	&:hover {
		@mixin hover-image;
	}

	@media(max-width: 1079px){
		top: 16px;
		right: 8px;
		img {
			width: 40px;
			height: 32px;
		}
	}
}