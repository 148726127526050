/* // -------------------------------------------------------------------------------------------------
// // footer#page-footer
---------------------------------------------------------------------------------------------------- */


/* //.	- basics
---------------------------------------------------------------------------------------------------- */

.page-footer {
	clear: both;
	width: 100%;
	background-color: #eeeeee;
}
.page-footer-inner {
	max-width: $w-base;
	padding: 64px 0 32px;
	margin: 0 auto;

	@media(max-width: $w-base) {
		@mixin sp-base;
		padding-top: 45px;
		padding-bottom: 30px;
	}
}


/* //..	.footer-site-id
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
.footer-site-id {
	text-align: center;
	.footer-siteid__image {
		display: inline-block;
		text-align: center;
	}
	.footer-siteid__text {
		display: block;
		text-align: center;
		font-size: $fs-base;
		line-height: $fs-base;
		font-weight: bold;
		color: $fc-gray;
		padding: 16px 0;
	}
}

/* //..	.media-nav
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
.media-nav__itemlist {
	display: flex;
	justify-content: center;
	margin-bottom: 24px;
}
.media-nav__item {
	padding: 0 12px;
	@media(max-width: $w-breakpoint) {
		img {
			width: 45px;
			height: 45px;
		}
	}
}

/* //..	.footer-menu
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
.footer-menu__itemlist {
	display: flex;
	justify-content: center;
	margin-bottom: 24px;

	@media(max-width: $w-base) {
		flex-wrap: wrap;
		margin-bottom: 15px;
		 justify-content: space-between;
	}
}
.footer-menu__item {
	width: 160px;
	text-align: center;
	border-top: 2px solid #dadada;
	border-bottom: 2px solid #dadada;
	margin: 0 12px;
	a {
		display: inline-block;
		height: 48px;
		line-height: 48px;
		font-size: $fs-base;
		font-weight: bold;
		text-decoration: none;
		color: $fc-gray;
		&:hover {
			color: $fc-hover;
			transition: 0.2s;
		}
	}
	@media(max-width: $w-base) {
		width: 48%;
		margin: 0;
		box-sizing: border-box;
		&:nth-child(-n+4) {
			border-bottom: none;
		}
		a {
			width: 100%;
		}
	}
}


/* //..	.footer-submenu
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
.footer-submenu__itemlist {
	display: flex;
	justify-content: center;
	margin-bottom: 32px;
}
.footer-submenu__item {
	padding: 0 12px;
	border-right: 1px solid $cl-gray;
	&:last-child {
		border-right: none;
	}
	a {
		font-size: 14px;
		color: $fc-gray;
		text-decoration: none;
		&:hover {
			color: $fc-hover;
			transition: 0.2s;
		}
	}
}

/* //..	.related-nav
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
.related-nav__itemlist {
	display: flex;
	justify-content: center;
	margin-bottom: 8px;
}
.related-nav__item {
	padding: 0 12px;
	border-right: 1px solid $cl-gray;
	&:last-child {
		border-right: none;
	}
	a {
		font-size: 12px;
		color: $fc-gray;
		text-decoration: none;
		&:hover {
			color: $fc-hover;
			transition: 0.2s;
		}
	}
}


/* //.	.terms-and-policies-nav
---------------------------------------------------------------------------------------------------- */
.terms-and-policies-nav__itemlist {
	display: flex;
	justify-content: center;
	margin-bottom: 8px;
}
.terms-and-policies-nav__item {
	padding: 0 12px;
	border-right: 1px solid $cl-gray;
	&:last-child {
		border-right: none;
	}
	a {
		font-size: 12px;
		color: $fc-gray;
		text-decoration: none;
		&:hover {
			color: $fc-hover;
			transition: 0.2s;
		}
	}
}

/* //.	address
---------------------------------------------------------------------------------------------------- */

.copyright {
	text-align: center;
	font-size: 1.1rem;
	color: $fc-gray;

	a {
		text-decoration: none;
		color: $fc-gray;
	}
}

/* //.	nav-pagetop
---------------------------------------------------------------------------------------------------- */
.nav-pagetop {
	display: none;
	width: 48px;
	height: 48px;
	background: #777777;
	border-radius: 10px;
	position: fixed;
	bottom: 25px;
	right: 25px;
	z-index: 100;
	&:before {
		content: '';
		width: 10px;
		height: 10px;
		border: 0px;
		border-top: solid 2px #fff;
		border-right: solid 2px #fff;
		transform: rotate(-45deg);
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: calc(10px/2*-1);
		margin-left: -5px;
	}
	&:hover {
		opacity: 0.8;
	}

	@media(max-width: $w-breakpoint) {
		width: 40px;
		height: 40px;
		background: rgba(0,0,0,0.6);
		bottom: 15px;
		right: 15px;
	}
}
