/* // -------------------------------------------------------------------------------------------------
// // div#page-content-body
---------------------------------------------------------------------------------------------------- */

/* //.	- .content-section
---------------------------------------------------------------------------------------------------- */

.content-section {
	margin-bottom: 40px;
	figure {
		margin-bottom: 40px;
	}
	figure img {
		width: 100%;
	}
	ul li {
		list-style: disc;
		margin-left: 1em;
		margin-bottom: 0.5em;
	}
	@media(max-width: $w-breakpoint) {
		figure {
			margin-bottom: 20px;
		}
	}
}
.section-heading {
	margin-bottom: 6px;
}
.section-title {
	font-size: 2.4rem;
	@mixin lineHeight 24, 30;
	font-weight: bold;
	@media(max-width: $w-breakpoint) {
		font-size: 1.8rem;
		@mixin lineHeight 18, 24;
	}
}
.provisions li {
	padding-left: 2.5em !important;
	margin-bottom: 24px;
	&:last-child {
		margin-bottom: 0;
	}
	ul li {
		text-indent: -2.5em;
		padding-left: 0 !important;
		margin-left: 3em !important;
		margin-top: 8px;
		margin-bottom: 0;
	}
}

.p-group {
	margin-bottom: 30px;
}
.p-group.note {
	font-size: 1.4rem;
	line-height: 1.5;
	color: #999999;
}

/* //..	[sole] FAQ: Q&A / section.q-and-a
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
.content-section--faq section {
	margin-top: 16px;
	h3 {
		font-size: 1.8rem !important;
	}
}
.content-section--faq dd {
	margin-bottom: 8px;
}
.content-section--faq ul li {
	list-style: disc outside;
	margin-left: 2em;
}


/* //..	[sole] .mailmagazine-form
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
.mailmagazine-form form {
	text-align: center;
	margin-bottom: 40px;
	input[type="text"] {
		display: block;
		width: 100%;
		font-size: $fs-base;
		border: 1px solid #cccccc;
		padding: 16px;
		border-radius: 6px;
		margin-bottom: 16px;
		box-sizing: border-box;
	}
	input[type="submit"] {
		display: inline-block;
		font-size: $fs-base;
		text-align: center;
		background-color: $cl-red;
		color: $fc-white;
		padding: 16px 72px;
		border-radius: 6px;
		&:before {
			@mixin arrow-right 6px, 1px, #fff;
		}
		&:hover {
			cursor: pointer;
			background: color($cl-red l(50%));
			transition: 0.2s;
		}
	}
}
@media(max-width: $w-breakpoint) {
	input[type="submit"] {
		width: 100%;
		display: block;
	@mixin arrow-right 6px, 1px, $fc-link;
	padding-left: 12px;
	}
}


/* //..	[sole] Sitemap: Content Tree / sitemap_content-tree
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
.sitemap {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 40px;
	@media(max-width: $w-base) {
		@mixin sp-base;
	}
	@media(max-width: $w-breakpoint) {
		display: block;
		margin-bottom: 32px;
	}
}
.sitemap__content {
	@mixin col-st 3,3n;
	margin-bottom: 8px;

	@media(max-width: $w-base) {
		&:nth-child(3n) {
			margin-right: calc(24 / 1080 *100)%;
		}
		@mixin col-st 2,2n;
	}
	@media(max-width: $w-breakpoint) {
		width: 100%;
	}
}
.sitemap__content-header {
	border-bottom: 1px solid #cccccc;
	margin-bottom: 8px;
}
.sitemap__content-title {
	font-size: 2.4rem;
	a {
		font-weight: bold;
		text-decoration: none;
	}
}
.sitemap__content-tree {
	margin-bottom: 32px;
}
.sitemap__content-treeitem a {
	@mixin lineHeight 16, 26;
	@mixin arrow-right 6px, 1px, $fc-link;
	font-weight: bold;
	padding-left: 12px;
	&:before {
		top: 12px;
	}
}