/* // -------------------------------------------------------------------------------------------------
// // header#page-content-header : for Works
---------------------------------------------------------------------------------------------------- */


/* //..	[instance] Work: Introduction Nav / nav.work_introduction-nav
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

header#page-content-header nav.work_introduction-nav {
	padding-bottom: 15px;
}

	header#page-content-header nav.work_introduction-nav ul {
		overflow: hidden;
	}

		header#page-content-header nav.work_introduction-nav ul li {
			list-style-type: none;
			float: left;
			padding-right: 1px;
		}

			header#page-content-header nav.work_introduction-nav ul li a {
				display: block;
				padding: 3px 5px;
		        border-radius: 0 0 6px 6px;
				border-top: solid 1px rgb(245,173,47);
				min-height: 21px;
				text-decoration: none;
				color: black;
				text-shadow: 1px 1px rgb(250,192,100);
				background-color: rgb(255,183,57);
			}

			header#page-content-header nav.work_introduction-nav ul li a:hover {
				color: rgb(230,0,18) !important;
				text-shadow: none;
				background-color: rgb(255,183,57);
			}


/* //..	[instance] Work: Action Nav / nav.work_action-nav
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

header#page-content-header nav.work_action-nav {
	position: relative;
	z-index: 10;
	float: right;
	margin-top: 0;
	margin-bottom: 20px;
	padding-left: 9px;
	@media(max-width: $w-breakpoint) {
		float: none;
	}
}

	header#page-content-header nav.work_action-nav ul {
		overflow: hidden;
		display: inline-block;
	}

		header#page-content-header nav.work_action-nav ul li {
			list-style-type: none;
			float: left;
			padding-right: 2px;
		}

/* //..	[instance] Work: New Episode / section.work_new-episode
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

header#page-content-header section.work_new-episode {
	position: relative;
	z-index: 1;
	margin: 0 0 20px;
	@media(max-width: $w-breakpoint) {
		@mixin sp-base;
	}
}

	header#page-content-header section.work_new-episode hgroup {}

		header#page-content-header section.work_new-episode hgroup h2 {
			margin-bottom: .5rem;
			font-size: 1.1rem;
			font-weight: bold;
		}

		header#page-content-header section.work_new-episode hgroup h3 {
			line-height: 1.2;
			font-size: 1.4rem;
		}

	header#page-content-header section.work_new-episode dl {
		margin: .5em 0 0;
	}

		header#page-content-header section.work_new-episode dl dt,
		header#page-content-header section.work_new-episode dl dd {
			margin: 0;
			padding-bottom: .3rem;
			font-size: 1.1rem;
			line-height: 1.3;
		}

		header#page-content-header section.work_new-episode dl dt {
			clear: left;
			float: left;
			padding-right: .5rem;
		}

		header#page-content-header section.work_new-episode dl dt:after {
			content: " : ";
		}

		header#page-content-header section.work_new-episode dl dd {
			padding-left: 5rem;
		}

			header#page-content-header section.work_new-episode dl dd strong {
				margin-left: -.3rem;
				font-weight: normal;
			}

				header#page-content-header section.work_new-episode dl dd strong:before,
				header#page-content-header section.work_new-episode dl dd strong:after {
					margin: 0 .2rem;
					color: rgb(160,160,160);
				}

				header#page-content-header section.work_new-episode dl dd strong:before {
					content: "《";
					margin-left: 0;
				}

				header#page-content-header section.work_new-episode dl dd strong:after {
					content: "》";
					margin-right: 0;
				}
