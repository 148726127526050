@charset "utf-8";



/* // -------------------------------------------------------------------------------------------------
// //.	Distilling
---------------------------------------------------------------------------------------------------- */


/* //.		- reset
---------------------------------------------------------------------------------------------------- */

html, body,
div,
h1, h2, h3, h4, h5, h6,
p, address,
figure, blockquote, pre,
ul, ol, menu, li,
dl, dt, dd,
caption, th, td,
form, fieldset, legend, input, textarea,
code {
	margin: 0;
	padding: 0;
	border: none 0;
	text-align: left;
}

h1, h2, h3, h4, h5, h6 {
	font-size: 100%;
}

h1, h2, h3, h4, h5, h6,
address, blockquote,
caption, th,
a, q,
em, strong, dfn, code, samp, kbd, var, cite,
abbr, acronym {
	font-style: normal;
	font-weight: normal;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

th, td {
	vertical-align: top;
}

li {
	list-style: none outside;
}

fieldset,
abbr, acronym,
img {
	border: none 0;
}

hr {
	display: none;
}


/* //.		- HTML5 elements
---------------------------------------------------------------------------------------------------- */

header,
footer,
article,
aside,
hgroup,
section,
figure,
nav {
	margin: 0;
	padding: 0;
	display: block;
}

time {
	display: inline;
}


/* //.		1em = 10px
---------------------------------------------------------------------------------------------------- */

body {
	font-size: 10px;
}
/*\*/
*:first-child+html body {
	font-size: 62.5%;
}
* html body {
	font-size: 62.5%;
}
/**/


/* //.		- common classes
---------------------------------------------------------------------------------------------------- */

.non-visual {
	display: block !important;
	position: absolute !important;
	overflow: hidden !important;
	margin: 0 !important;
	padding: 0 !important;
	border: 0 !important;
	left: 0 !important;
	top: 0 !important;
	width: 0 !important;
	height: 0 !important;
	background: transparent !important;
}

.separator {
	display: block;
	overflow: hidden;
	margin: 0;
	padding: 0;
	border: solid black;
	border-width: 1px 0 0;
	height: 0;
}

.uppercase {
	text-transform: uppercase !important;
}


