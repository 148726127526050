/* // -------------------------------------------------------------------------------------------------
// // publications
---------------------------------------------------------------------------------------------------- */

/* //..	.search-box
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

.search-box {}
.search-box__inner {
	margin-bottom: 64px;
	@media(max-width: $w-breakpoint) {
		margin-bottom: 40px;
	}
}
.gsc-input-box  {
	height: 48px!important;
	border-radius: 6px;
}
form.gsc-search-box {
	width: 576px!important;
	margin: 0 auto!important;

	@media(max-width: $w-breakpoint) {
		width: 100%!important;
	}
}
input#gsc-i-id1.gsc-input {
	height: 44px!important;
	font-size: 16px;
	margin-top: 0!important;
}


input.gsc-search-button {
	background-color: $cl-red!important;
	border-color: $cl-red!important;
	}
	input.gsc-search-button:hover,
	input.gsc-search-button:focus{}
input.gsc-search-button-v2 {
	border: none!important;
	padding: 16px 27px!important;
	border-radius: 6px;
}

/* //..	.books__itemlist
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
.books__itemlist {
	display: flex;
	flex-wrap: wrap;

	@media(max-width: $w-base) {
		width: 100%;
		@mixin sp-base;
	}

}
.books__item {
	@mixin col-st 4,4n;
	padding-bottom: 16px;
	margin-bottom: 56px;
	position: relative;

	@media(max-width: $w-base) {
		&:nth-child(4n){
			margin-right: calc(24 / 1080 *100)%;
		}
		@mixin col-st 3,3n;
	}
	@media(max-width: $w-breakpoint) {
		@mixin col-st 2,2n;
		margin-bottom: 35px;
		&:nth-child(odd){
			margin-right: calc(30 / 1080 *100)%;
		}
	}
}
a.books__itemlink {
	display: block;
	text-decoration: none;
	color: $fc-base;
	&:hover {
		@mixin hover-image;
		color: $fc-hover;
	}
}
.books__image {
	text-align: center;
	width: 160px;
	height: auto;
	margin: 0 auto 18px;
	@media(max-width: $w-breakpoint) {
		width: calc(220 / 330 *100)%;
		min-height: auto;
	}
	a {
		display: block;
		&:hover {
			@mixin hover-image;
		}
	}
	img {
		width: 100%;
		border: 1px solid #e5e5e5;
	}
}
.books__name  {
	margin-bottom: 10px;
	h2 {
		font-size: 1.6rem;
		font-weight: bold;
		@mixin lineHeight 16,22;
		min-height: 3em;
		@media(max-width: $w-breakpoint) {
			font-size: 1.4rem;
			@mixin lineHeight 14,18;
		}
	}
}
.books__data {
	width: 100%;
	margin-bottom: 16px;
	@media(max-width: $w-breakpoint) {
		margin-bottom: 82px;
	}
}
.books__dataitem {
	font-size: 1.4rem;
	@mixin lineHeight 14,18;
	@media(max-width: $w-breakpoint) {
		font-size: 1.2rem;
	}
}
.books__data__title {
	font-weight: normal;
}
.books__data__item {
	word-break: break-all;
	margin-bottom: 0;
}
.books__dataitem--author,
.books__dataitem--isbn {
	.books__data__title {
		display: table-cell;
		width: 3.5em;
	}
	.books__data__item {
		display: table-cell;
		word-break: break-all;
		margin-bottom: 0;
		-webkit-tap-highlight-color: none;
	}
}
.book-entry__dataitem--label {
	display: inline-block;
	background-color: #004ea2;
	border-radius: 4px;
	margin-bottom: 8px;
	span {
		height: 20px;
		line-height: 20px;
		font-size: 1.1rem;
		color: $fc-white;
		padding: 0 16px;
	}
}
.books__buttons {
	display: table;
	position: absolute;
	bottom: 0;
	@media(max-width: $w-breakpoint) {
		display: block;
		width: 100%;
	}
}
.books__button {
	display: table-cell;
	width:  124px;
	a {
		height: 24px;
		font-size: 12px;
		line-height: 24px;
		text-decoration: none;
		text-align: center;
		border-radius: 4px;
	}
	@media(max-width: $w-breakpoint) {
		display: block;
		width: 100%;
		a {
			width: 100%;
			height: 30px;
			line-height: 30px;
		}
	}
}
.books__button--more {
	a {
		display: block;
		color: $fc-white;
		background-color: $cl-red;
		margin-right: 2px;
		&:hover {
			cursor: pointer;
			background: color($cl-red l(50%));
			transition: 0.2s;
		}
	}
	@media(max-width: $w-breakpoint) {
		a {
			margin-right: 0;
		}
	}
	span {
			padding-left: 12px;
		@media(min-width: $w-breakpoint) {
			@mixin arrow-right 6px , 1px, #fff;
		}
		@media(max-width: $w-breakpoint) {
			@mixin arrow-right 6px , 1px, #fff;
		}
	}
}
.books__button--amazon {
	a {
		display: block;
		color: $fc-base;
		background-color: $cl-white;
		border: 1px solid $cl-lightgray;
		margin-left: 2px;
		&:hover {
			cursor: pointer;
			opacity: 0.8;
			transition: 0.2s;
		}
		@media(max-width: $w-breakpoint) {
			margin-left: 0;
			margin-top: 8px;
		}
	}
	span {
		padding-left: 12px;
		@media(min-width: $w-breakpoint) {
			@mixin arrow-right 6px , 1px, #fab455;
		}
		@media(max-width: $w-breakpoint) {
			@mixin arrow-right 6px , 1px, #fab455;
		}
	}
}


/*- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
/* //..	.book-entry
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
$book-entry__headingsize: calc(344/1080*100)%;
.book-entry {
	width: 100%;
	max-width: $w-base;
	margin: 0 auto 64px;
	display: table;
	@media(max-width: $w-base) {
		@mixin sp-base;
	}
	@media(max-width: $w-breakpoint) {
		display: block;
		margin-bottom: 45px;
	}
}
.book-entry__title {
	font-size: $fs-h2;
	font-weight: bold;
	@mixin lineHeight 24,30;
	@media(max-width: $w-breakpoint) {
		font-size: 1.8rem;
		@mixin lineHeight 36,46;
	}
}
.book-entry__header {
	display: table-cell;
	width: $book-entry__headingsize;
	@media(max-width: $w-base) {
		left: 15px;
	}
	@media(max-width: $w-breakpoint) {
		display: block;
		width: 100%;
	}
}
.book-entry__image {
	text-align: center;
	min-height: 240px;
	margin-bottom: 24px;
	img {
		border: 1px solid #e5e5e5;
		box-sizing: border-box;
	}
}
.book-entry__data {
	margin-bottom: 24px;
}
.book-entry__dataitem {
	font-size: 1.4rem;
	@mixin lineHeight 14,20;
}
.book-entry__button {
	width: 100%;
	text-align: center;
	border-radius: 4px;
	border: 2px solid $cl-lightgray;
	margin-bottom: 16px;
	padding: 8px 0;
	box-sizing: border-box;
	a {
		display: block;
		border: none;
		margin-left: 0;
		&:hover {
			@mixin hover-image;
		}
	}
	@media(max-width: $w-breakpoint) {
		padding: 4px 0;
		img {
			width: 65px;
			height: 30px;
		}
	}
}

.book-entry__body {
	display: table-cell;
	vertical-align: top;
	padding-left: 24px;
	@media(max-width: $w-breakpoint) {
		display: block;
		width: 100%;
		padding-left: 0;
	}
}
.book-entry____socialbuttons {
	background-color: #f3f3f3;
	padding: 8px 24px;
	margin-bottom: 24px;
	@media(max-width: $w-breakpoint) {
		padding: 8px;
	}
}
.book-entry____socialbutton {
	display: inline-block;
	vertical-align: top;
	margin-right: 16px;
}
.book-entry__intro {
	margin-bottom: 40px;
	p {
		font-size: $fs-base;
		@mixin lineHeight 16, 22;
	}
}
.book-entry__pdflink {
	width: 100%;
	max-width: 252px;
	text-align: center;
	margin-top: 24px;
	@media(max-width: $w-breakpoint) {
		max-width: 100%;
	}
	a {
		display: block;
		width: 100%;
		height: 56px;
		line-height: 56px;
		background-color: $cl-red;
		text-decoration: none;
		font-size: 1.4rem;
		color: $fc-white;
		border-radius: 6px;
		&:hover {
			cursor: pointer;
			background: color($cl-red l(50%));
			transition: 0.2s;
		}
		@media(max-width: $w-breakpoint) {
			height: 45px;
			line-height: 45px;
		}
	}
	span {
		display: inline-block;
		height: 100%;
		background: url(/res/img/common/page-top/page-content/publications/icn-pdf.png) no-repeat left center;
		background-size: 20px auto;
		padding-left: 40px;
		@media(max-width: $w-breakpoint) {
			background-size: 18px auto;
		}
	}
}
.book-entry__comment {
	border: 1px solid $cl-gray;
	border-radius: 8px;
	padding: 24px;
	box-sizing: border-box;
	@media(max-width: $w-breakpoint) {
		padding: 15px;
	}
}
.book-entry__comment__title {
	font-size: 1.6rem;
	font-weight: bold;
	@mixin lineHeight 16,22;
	margin-bottom: 16px;
}
.book-entry__comment__text {
	@mixin lineHeight 16,22;
	margin-bottom: 16px;
}
.book-entry__comment__editor {
	font-size: 0;
	span {
		display: inline-block;
		vertical-align: middle;
		font-size: 1.6rem;
		color: #666666;
	}
}
.book-entry__comment__icon {
	width: 30px;
	height: 30px;
	border-radius: 100%;
	margin-right: 5px;
	overflow: hidden;
	img {
		width: 100%;
		height: auto;
	}
}
