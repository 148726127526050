/* // -------------------------------------------------------------------------------------------------
// // New Face Award
---------------------------------------------------------------------------------------------------- */

.category-menu--award {
	margin-top: 8px;
	@media(max-width: $w-breakpoint) {
		margin-top: 16px;
	}
}


/* //..	[sole] New Face Award: Message / .prize-money
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
.prize-money {
	width: 712px;
	margin: 8px auto 24px;
	@media(max-width: $w-breakpoint) {
		width: 100%;
		@mixin sp-base;
		margin-bottom: 30px;
	}
}
.prize-money__inner {
	background-color: $cl-red;
	border-radius: 8px;
	padding: 20px;
	box-sizing: border-box;
}
	.prize-money__heading {
		text-align: center;
		color: $fc-white;
		margin-bottom: 10px;
		span {
			display: block;
		}
		em {
			font-size: 2.4rem;
			font-style: normal;
		}
	}
	.prize-money__body {
		background-color: #000;
		color: #fff;
		border-radius: 4px;
		margin-bottom: 10px;
		p {
			text-align: center;
		}
		strong {
			font-weight: bold;
			font-size: 6.5rem;
			@media(max-width: $w-breakpoint) {
				font-size: 4rem
			}
		}
		.p-small {
			font-size: 1.4rem;
			color: #999999;
			padding-bottom: 10px;
		}
	}
	.prize-money__footer {
		p {
			text-align: center;
			color: $fc-white;
		}
		.p-small {
			font-size: 1.1rem;
		}
	}

/* //..	[sole] New Face Award: Message / section#new-face-award_message
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

div#page-content-body section#new-face-award_message {
	width: 712px;
	margin: 15px auto 56px;
	@media(max-width: $w-breakpoint) {
		width: 100%;
		@mixin sp-base;
		margin-top: 0;
		margin-bottom: 30px;
	}
}

	div#page-content-body section#new-face-award_message header {
		padding: 0;
		background: transparent;
	}

		div#page-content-body section#new-face-award_message header h2 {
			color: black;
			line-height: 1.5;
			font-size: 2.4rem;
			font-weight: bold;
			margin-bottom: 14px;
			@media(max-width: $w-breakpoint) {
				font-size: 1.6rem;
			}
		}
	div#page-content-body section#new-face-award_message .section-body {
		line-height: 1.5;
		margin-bottom: 15px;
	}

	div#page-content-body section#new-face-award_message footer {}

		div#page-content-body section#new-face-award_message footer p {
			text-align: right;
			font-size: 1.5rem;
		}


/* //..	[sole] New Face Award: Guidelines / section#new-face-award_guidelines
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

section#new-face-award_guidelines {
	width: 712px;
	margin: 0 auto;
	@media(max-width: $w-breakpoint) {
		width: 100%;
		@mixin sp-base;
	}
}
	section#new-face-award_guidelines header {
		padding-left: 24px!important;
		border-radius: 8px 8px 0 0!important;
		h2 {
			font-size: 1.6rem!important;
			line-height: 32px!important;
			height: 32px!important;
		}
	}
	section#new-face-award_guidelines div.section-body {
		padding: 0 15px !important;
		border: solid rgb(203,203,203);
		border-width: 0 1px 1px;
	}

		section#new-face-award_guidelines div.section-body table {
			position: relative;
			top: .3em;
		}

			section#new-face-award_guidelines div.section-body table th {
				width: 136px;
				border-top: solid 1px rgb(203,203,203);
				font-weight: bold;
				padding: 16px 24px 16px 0;
				@media(max-width: $w-breakpoint) {
					display: block;
					width: 100%;
					padding-bottom: 0;
					box-sizing: border-box;
				}
			}
			section#new-face-award_guidelines div.section-body table td {
				border-top: solid 1px rgb(203,203,203);
				padding: 16px 24px 16px 0;
				@media(max-width: $w-breakpoint) {
					width: 100%;
					display: block;
					border-top: none;
					padding: 0 0 16px;
				}
			}
			section#new-face-award_guidelines div.section-body table tr:first-child th,
			section#new-face-award_guidelines div.section-body table tr:first-child td {
				border-top: none 0
			}
			section#new-face-award_guidelines div.section-body table tr:last-child td {
				padding-bottom: 24px;
			} 

