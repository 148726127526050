/* // -------------------------------------------------------------------------------------------------
// // .page-content : Layout
---------------------------------------------------------------------------------------------------- */


/* //.	- layout
---------------------------------------------------------------------------------------------------- */

.page-content {
	width: 100%;
	@media(max-width: 1079px) {
		padding-top: 70px;
	}
}

.page-content-header {
	width: 100%;
	max-width: $w-base;
	padding-top: 24px;
	margin: 0 auto 24px;

	@media(max-width: $w-base) {
		@mixin sp-base;
		padding-top: 24px;
	}
}
.page-content-header--narrow {
	width: 100%;
	max-width: 710px;
	margin: 0 auto 40px;
	@media(max-width: $w-breakpoint) {
		@mixin sp-base;
		padding-top: 24px;
	}
}
.page-content-body__inner {
	max-width: $w-base;
	margin: 0 auto 40px;
	position: relative;
}
.page-content-body__inner--col2 {
	display: flex;
	@media(max-width: $w-base) {
	}
	@media(max-width: $w-breakpoint) {
		display: block;
	}
}
.page-content-body__inner--narrow {
	width: 100%;
	max-width: 710px;
	margin: 0 auto 40px;
	@media(max-width: $w-breakpoint) {
		@mixin sp-base;
	}
}
.page-content-main {
	width: calc(712/1080*100)%;
	margin-right: calc(24/1080*100)%;
	@media(max-width: $w-breakpoint) {
		width: 100%;
	}
}
.page-content-aside {
	width: calc(344/1080*100)%;
	@media(max-width: $w-breakpoint) {
		width: 100%;
	}
}

/* //.	- heading
---------------------------------------------------------------------------------------------------- */
.content-header {
	width: 100%;
	max-width: $w-base;
	margin: 0 auto 24px;
	@media(max-width: $w-base) {
		@mixin sp-base;
		margin-bottom: 16px;
	}
}
.page-content-aside .content-header {
	@media(max-width: $w-base) {
		padding: 0;
	}
	@media(max-width: $w-breakpoint) {
		padding: 0 15px;
	}
}
.page-heading,
.content-heading {
	position: relative;
}
.page-heading--1 {
	color: $fc-szsred;
	font-weight: bold;
	font-size: $fs-h1;
	@mixin lineHeight 32,38;
	
	@media(max-width: $w-base) {
		font-size: $fs-sp-h1;
		@mixin lineHeight 24,30;
		margin-bottom: 20px;
	}
}
.page-heading--2 {
	color: $fc-base;
	font-weight: bold;
	font-size: $fs-h2;
	@mixin lineHeight 24,24;
	padding-top: 24px;

	@media(max-width: $w-base) {
		font-size: $fs-sp-h2;
		@mixin lineHeight 18,23;
		margin-bottom: 15px;
		padding-top: 0;
	}
}
.content-heading--1 {
	color: $fc-base;
	font-weight: bold;
	font-size: $fs-h2;
	@mixin lineHeight 24,24;
	margin-bottom: 24px;

	@media(max-width: $w-base) {
		font-size: $fs-sp-h2;
		@mixin lineHeight 18,23;
		margin-bottom: 15px;
	}

}
.page-aside-heading {
	margin-top: 24px;
	position: relative;
	@media(max-width: $w-breakpoint) {
		box-sizing: border-box;
	}
}
.category-heading {
	color: $fc-base;
	font-weight: bold;
	font-size: $fs-h1;
	@mixin lineHeight 32,32;
	border-left: 2px solid $cl-lightgray;
	padding-left: 0.5em;
	margin-left: 0.5em;

	
	@media(max-width: $w-base) {
		font-size: $fs-sp-h1;
		@mixin lineHeight 24,28;
	}
}

.page-heading__viewall, 
.content-heading__viewall {
	width: 120px;
	height: 24px;
	text-align: center;
	background-color: $cl-red;
	border-radius: 6px;
	position: absolute;
	top: 0;
	right: 0;
	&:hover {
		cursor: pointer;
		background: color($cl-red l(50%));
		transition: 0.2s;
	}
	a {
		display: block;
		text-decoration: none;
		line-height: 24px;
		font-size: 1.2rem;
		color: $fc-white;
		padding-left: 14px;
		@mixin arrow-right 6px, 1px, #fff;
	}
	@media(max-width: $w-base) {
		right: 15px;
	}
	@media(max-width: $w-breakpoint) {
		display: none;
	}
}

.viewall-button {
	display: none;
	@media(max-width: $w-breakpoint) {
		@mixin sp-base;
		display: block;
		text-align: center;
		margin-bottom: 30px;
		a {
			display: block;
			width: 100%;
			height: 45px;
			background-color: $cl-red;
			text-decoration: none;
			line-height: 45px;
			font-size: 1.4rem;
			color: $fc-white;
			border-radius: 6px;
		}
		span {
			@mixin arrow-right 6px, 1px, #fff;
			padding-left: 14px;
		}
	}
}
.gotop-button {
	display: block;
	width: 60%;
	text-align: center;
	margin: 0 auto 64px;
	@media(max-width: $w-breakpoint) {
		display: none;
	}
	a {
		display: block;
		width: 100%;
		height: 45px;
		background-color: $cl-red;
		text-decoration: none;
		line-height: 45px;
		font-size: 1.4rem;
		color: $fc-white;
		border-radius: 6px;
		&:hover {
			cursor: pointer;
			background: color($cl-red l(50%));
			transition: 0.2s;
		}
	}
	span {
		@mixin arrow-right 6px, 1px, #fff;
		padding-left: 14px;
	}
}

/* //..	.category-menu
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
.category-menu {
	margin-bottom: 32px;
	label {
		position: relative;
	    display: block;
		width: 100%;
		height: 50px;
		border:1px solid $cl-lightgray;
	    border-radius: 0px;
	    background: #fff;
	    &:before {
	    	content: "";
	    	width: 24px;
	    	height: 50px;
	    	display: block;
	    	border-left: 1px solid #ccc;
		    position: absolute;
		    right: 0;
	    		    }
	    &:after {
		    content: "";
		    position: absolute;
		    top: 50%;
		    right: 6px;
		    display: block;
		    width: 0;
		    height: 0;
		    margin: -5px 0 0 0;
		    border: 6px solid transparent;
		    border-top: 10px solid #999;
		}
	}
}
.page-content-aside .category-menu {
	@media(max-width: $w-base) {
		@mixin sp-base;
		padding-left: 0;
	}
	@media(max-width: $w-breakpoint) {
		width: 100%;
		padding-left: 15px;
		margin-right: 0;
	}
	
}
.category-menu__selectbox {
	appearance: none;
	width: 100%;
	height: 50px;
	font-size: 1.4rem;
	cursor: pointer;
    position: relative;
    display: block;
    padding: 0.5em 1em;
    border: none;
    border-radius: 5px;
    background: transparent;
    z-index: 100;
	@media(max-width: $w-breakpoint) {
		font-size: 1.6rem;
	}

}
.category-menu-heading {
	margin-bottom: 8px;
}
.category-menu__title {
	font-size: 2.4rem;
	font-weight: bold;
	@mixin lineHeight 24, 30;
}

/* //.	- widget-twitter
---------------------------------------------------------------------------------------------------- */
.aside-widget {
	height: 100%;
	max-height: 400px;
}
.widget-twitter{
	height: 100%;
	max-height: 400px;
	margin-bottom: 16px;
	@media(max-width: $w-base) {
		@mixin sp-base;
	}
	@media(max-width: $w-breakpoint) {
		height: 400px;
		padding: 0 15px;
	}
}
.widget-twitter__inner{
	height: 100%;
	iframe {
		border: 1px solid #e6eaed!important;
		height: 100%!important;
		@media(max-width: $w-breakpoint) {
			min-height: 292px!important;
			height: 400px!important;
		}
	}
}
.page-content-aside .widget-twitter{
	@media(min-width: $w-breakpoint) and (max-width: $w-base) {
		padding-left: 0;
	}
}

/* //..	[Home]  .aside-banner
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
.aside-banner {
	width: 100%;
	margin-bottom: 16px;
	box-sizing: border-box;
	img {
		width: 100%;
		height: auto;
	}
	a {
		display: block;
		&:hover {
			@mixin hover-image;
		}
	}
	@media(max-width: $w-base) {
		padding-right: 15px;
	}
	@media(max-width: $w-breakpoint) {
		@mixin sp-base;
	}
}