/* // -------------------------------------------------------------------------------------------------
// // foundation
---------------------------------------------------------------------------------------------------- */

/* //.	- settings(width)
---------------------------------------------------------------------------------------------------- */
$w-base: 1080px;
$w-breakpoint: 750px;


/* //.	- settings(font-family)
---------------------------------------------------------------------------------------------------- */
/* sans-serif */
@font-face {
  font-family: "Helvetica Neue";
  src: local("Helvetica Neue Regular");
  font-weight: 100;
}
@font-face {
  font-family: "Helvetica Neue";
  src: local("Helvetica Neue Regular");
  font-weight: 200;
}

$ff-sans-serif: -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Meiryo, "メイリオ", 'ヒラギノ角ゴ ProN W3', Hiragino Kaku Gothic ProN, sans-serif;


/* //.	- settings(font-size)
---------------------------------------------------------------------------------------------------- */
$fs-base: 1.6rem;
$fs-h1: 3.2rem;
$fs-h2: 2.4rem;
$fs-h3: 2.2rem;

$fs-sp-h1: 2.4rem;
$fs-sp-h2: 1.8rem;
$fs-sp-h3: 1.9rem;

/* //.	- settings(font-color)
---------------------------------------------------------------------------------------------------- */
$fc-base: #333;
$fc-link: #395c9a;
$fc-hover: #ff9900;
$fc-szsred: #e60012;
$fc-gray: #999999;
$fc-white: #ffffff;

/* //.	- settings(color)
---------------------------------------------------------------------------------------------------- */
$cl-orange: #ff9900;
$cl-red: #e60012;
$cl-lightgray: #cccccc;
$cl-gray: #999999;
$cl-white: #ffffff;


/* //.	- mixins
---------------------------------------------------------------------------------------------------- */

/* 行間 */
@define-mixin lineHeight $baseFontSize , $lineHeight{
  line-height: calc($lineHeight / $baseFontSize);
}

/*レイアウト小の余白*/
@define-mixin sp-base {
	padding: 0 15px;
	box-sizing: border-box;
}

/* カラム幅・余白 */
@define-mixin col-st $col-num , $col-nomargin{
	$w-basewidth: 1080;
	$col-margin: calc( 24 * ($col-num - 1) );
	$col-wid: calc(($w-basewidth - $col-margin) / $col-num);

	width: calc($col-wid / $w-basewidth * 100)%;
	margin-right: calc(24 / 1080 *100)%;
	&:nth-child($col-nomargin) {
		margin-right: 0;
	}
	@media(max-width: $w-breakpoint) {
		$col-margin: calc( 30 * ($col-num - 1) );
		$col-wid: calc(($w-basewidth - $col-margin) / $col-num);
		width: calc($col-wid / $w-basewidth * 100)%;
		margin-right: calc(30 / 1080 *100)%;
	}
}

/* hover */
@define-mixin hover-image {
	transition: 0.2s ease-out;
	opacity: 0.8;
}


/* 矢印 */
@define-mixin arrow-right $arrow-size,$arrow-width,$arrow-color {
	display: inline-block;
	position: relative;
	&:before {
		content: '';
		width: $arrow-size;
		height: $arrow-size;
		border: 0px;
		border-top: solid $arrow-width $arrow-color;
		border-right: solid $arrow-width $arrow-color;
		transform: rotate(45deg);
		position: absolute;
		top: 50%;
		left: 0;
		margin-top: calc($arrow-size/2*-1);
	}
}

@define-mixin arrow-left $arrow-size,$arrow-width,$arrow-color {
	display: inline-block;
	position: relative;
	&:before {
		content: '';
		width: $arrow-size;
		height: $arrow-size;
		border: 0px;
		border-top: solid $arrow-width $arrow-color;
		border-right: solid $arrow-width $arrow-color;
		transform: rotate(-135deg);
		position: absolute;
		top: 50%;
		right: 0;
		margin-top: calc($arrow-size/2*-1);
	}
}