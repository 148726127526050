/* // -------------------------------------------------------------------------------------------------
// // div#page-content-body
---------------------------------------------------------------------------------------------------- */

/* //..	[class] Work: Page Link
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
@media(max-width: $w-base) {
	div#page-content-body .work_synopsis, 
	div#page-content-body .work_authors {
		padding-top: 75px !important;
		margin-top: -75px !important;
	}
}

/* //..	[class] Work: Introduction / section.work_introduction
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

div#page-content-body .work_introduction {
	margin: 0 0 15px;
	@media(max-width: $w-breakpoint) {
		@mixin sp-base;
	}
}

	div#page-content-body .work_introduction div.section-body {
		padding-left: 15px;
		padding-right: 15px;
		padding-bottom: 15px;
		border: solid rgb(203,203,203);
		border-width: 0 1px 1px;
	}

	div#page-content-body .work_introduction div.section-body section {
		margin-bottom: 15px;
	}

	div#page-content-body .work_introduction div.section-body section:last-child {
		margin-bottom: 0 !important;
	}


/* //..	[instance] Work: Introduction: Publications / section.work_publications
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

div#page-content-body section.work_publications {
	clear: both;
	@media(max-width: $w-breakpoint) {
		@mixin sp-base;
	}
}
	div#page-content-body section.work_publications div.section-body {
		overflow: hidden;
		padding: 5px 10px;
	}

	div#page-content-body section.work_publications div.section-body ul {
		margin: 0;
		padding: 0;
	}

		div#page-content-body section.work_publications div.section-body ul li {
			list-style: outside none;
			float: left;
			position: relative;
			margin: 0;
			padding: 7px 10px;
			width: 176px;
			font-size: 1rem;
			@media(max-width: $w-breakpoint) {
				float: none;
				width: 100%;
			    margin: 0 auto;
			}
		}

			div#page-content-body section.work_publications div.section-body ul li a {
				display: block;
				padding: 8px 4px;
				border: solid 1px white;
		        border-radius: 8px;
				text-decoration: none !important;
				color: black;
			    transition: color 0.125s ease-out, border-color 0.125s ease-out, text-shadow 0.125s ease-out, background-color 0.125s ease-out;
			}
			div#page-content-body section.work_publications div.section-body ul li a:hover {
				color: darkorange;
				text-shadow: 0 1px 3px white;
			}
			div#page-content-body section.work_publications div.section-body ul li a:active {
				color: orange;
			}
			div#page-content-body section.work_publications div.section-body ul li a img {
		        transition: opacity 0.125s ease-out;
			}
			div#page-content-body section.work_publications div.section-body ul li a img:hover {
				opacity: 0.8;
			}
			div#page-content-body section.work_publications div.section-body ul li a:active img {
				opacity: 0.6;
			}

				div#page-content-body section.work_publications div.section-body ul li a span.item-image,
				div#page-content-body section.work_publications div.section-body ul li a strong.item-name,
				div#page-content-body section.work_publications div.section-body ul li a em.item-contributor,
				div#page-content-body section.work_publications div.section-body ul li a span.item-amazon-button {
					display: block;
					width: 100%;
					text-align: center;
				}

					div#page-content-body section.work_publications div.section-body ul li a span.item-image img {
						border: solid 1px white;

						@media(max-width: $w-breakpoint) {
							width: 150px;
						}
					}

				div#page-content-body section.work_publications div.section-body ul li a strong.item-name {
					margin: 5px 0;
					font-size: 1.2rem;
					line-height: 1.3;
				}
				div#page-content-body section.work_publications div.section-body ul li a strong.item-name:hover {
					text-decoration: underline !important;
				}

					div#page-content-body section.work_publications div.section-body ul li a strong.item-name small {
						display: block;
						margin-top: 5px;
						font-size: .86rem;
						line-height: 1.1;
					}

				div#page-content-body section.work_publications div.section-body ul li a span.item-amazon-button {
					display: block;
					width: 100%;
					text-align: center;
				}

					div#page-content-body section.work_publications div.section-body ul li a span.item-amazon-button img {
						width: 120px;
						height: 20px;
					}


/* //..	[instance] Work: Introduction: Back Numbers [COMMON]
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

	div#page-content-body .work_back-numbers div.section-body,
	div#page-content-body .work_back-numbers-without-notes div.section-body,
	div#page-content-body .work_back-numbers-with-synopsis div.section-body,
	div#page-content-body .work_back-numbers-with-chapters div.section-body {
		border: none !important;
	}
	div#page-content-body .work_back-numbers div.section-body ul li {
		font-size: 1.4rem;
	}


/* //..	[instance] Work: Introduction: Back Numbers / .work_back-numbers
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

	div#page-content-body .work_back-numbers div.section-body {
		padding: 0 !important;
		background: transparent !important;
		background: rgb(225,225,225) !important;
	}

		div#page-content-body .work_back-numbers div.section-body ul {
			margin-bottom: 10px;
			border-bottom: solid 1px white;
		}

			div#page-content-body .work_back-numbers div.section-body ul li {
				overflow: hidden;
				position: relative;
				margin-bottom: 0;
				margin-left: 0;
				padding: 2px 15px 3px 13px;
				border-top: solid 1px white;
				line-height: 1.4;
				background-color: rgb(237,237,237);
			}
			div#page-content-body .work_back-numbers div.section-body ul li:nth-child(2n) {
				background-color: rgb(225,225,225);
			}

				div#page-content-body .work_back-numbers div.section-body ul li strong.attention {
					display: inline-block;
					padding: 0 .4rem;
					width: 4em;
					height: 1.5rem;
					line-height: 1.5rem;
					font-size: .8em;
					margin-right: 1em;
				}

				div#page-content-body .work_back-numbers div.section-body ul li a:link {
					color: rgb(230,0,18);
				}

				div#page-content-body .work_back-numbers div.section-body ul li em.note {
					position: relative;
					right: -12px;
					float: right;
					padding-top: .3rem;
					font-size: .8rem;
				}
				div#page-content-body .work_back-numbers div.section-body ul li em.note,
				div#page-content-body .work_back-numbers div.section-body ul li em.note * {
					font-weight: bold;
				}

					div#page-content-body .work_back-numbers div.section-body ul li em.note:before,
					div#page-content-body .work_back-numbers div.section-body ul li em.note:after {
						position: relative;
						color: rgb(96,96,96);
					}

					div#page-content-body .work_back-numbers div.section-body ul li em.note:before {
						content: "【";
						margin-right: .1rem;
					}

					div#page-content-body .work_back-numbers div.section-body ul li em.note:after {
						content: "】";
						margin-left: .1rem;
					}


/* //..	[class] Work: Introduction: Back Numbers Without Notes / .work_back-numbers-without-notes
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

	div#page-content-body .work_back-numbers-without-notes div.section-body {
		padding: 10px 15px 15px !important;
		background: rgb(237,237,237);
	}

		div#page-content-body .work_back-numbers-without-notes div.section-body ul {
			overflow: hidden;
			margin: 0;
			padding: 5px 0;
			border-radius: 8px;
			background: white;
		}

			div#page-content-body .work_back-numbers-without-notes div.section-body ul li {
				list-style: none outside;
				position: relative;
				left: -1px;
				float: left;
				margin: 3px 0;
				padding: 0 8px;
				border-left: solid 1px rgb(225,225,225);
				line-height: 1.1;
				font-size: 1.3rem;
				text-align: center;
				background: transparent;
			}

				div#page-content-body .work_back-numbers-without-notes div.section-body ul li span.disabled {
					color: darkgray;
				}


/* //..	[class] Work: Introduction: Back Numbers (With Synopsis | Chapters) : Chapter
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */


	div#page-content-body .work_back-numbers-with-synopsis div.section-body,
	div#page-content-body .work_back-numbers-with-chapters div.section-body {
		padding: 10px 0 !important;
		background: rgb(225,225,225);
	}

		div#page-content-body .work_back-numbers               div.section-body section.chapter,
		div#page-content-body .work_back-numbers-with-synopsis div.section-body section,
		div#page-content-body .work_back-numbers-with-chapters div.section-body section {
			position: relative;
			margin: 0 !important;
			padding: 0 15px 10px !important;
		}
		div#page-content-body .work_back-numbers               div.section-body section.chapter:first-child,
		div#page-content-body .work_back-numbers-with-synopsis div.section-body section:first-child,
		div#page-content-body .work_back-numbers-with-chapters div.section-body section:first-child {
			margin-top: 0 !important;
		}

			div#page-content-body .work_back-numbers               div.section-body section.chapter h3,
			div#page-content-body .work_back-numbers-with-synopsis div.section-body section h3,
			div#page-content-body .work_back-numbers-with-chapters div.section-body section h3,
			div#page-content-body .work_back-numbers               div.section-body section.chapter div.pgroup,
			div#page-content-body .work_back-numbers-with-synopsis div.section-body section div.pgroup,
			div#page-content-body .work_back-numbers-with-chapters div.section-body section div.pgroup {
				margin: 0;
				padding: 8px;
				background: white;
			}

			div#page-content-body .work_back-numbers               div.section-body section.chapter h3,
			div#page-content-body .work_back-numbers-with-synopsis div.section-body section h3,
			div#page-content-body .work_back-numbers-with-chapters div.section-body section h3 {
		        border-radius: 8px 8px 0 0;
				font-weight: normal;
			}

				div#page-content-body .work_back-numbers               div.section-body section.chapter h3 strong,
				div#page-content-body .work_back-numbers-with-synopsis div.section-body section h3 strong,
				div#page-content-body .work_back-numbers-with-chapters div.section-body section h3 strong {
					font-weight: bold;
				}

			div#page-content-body .work_back-numbers               div.section-body section.chapter p.info,
			div#page-content-body .work_back-numbers-with-synopsis div.section-body section p.info,
			div#page-content-body .work_back-numbers-with-chapters div.section-body section p.info {
				position: absolute;
				float: right;
				top: 9px;
				right: 24px;
				font-size: 1.1rem;
			}

				div#page-content-body .work_back-numbers               div.section-body section.chapter p.info strong,
				div#page-content-body .work_back-numbers-with-synopsis div.section-body section p.info strong,
				div#page-content-body .work_back-numbers-with-chapters div.section-body section p.info strong {
					float: right;
					margin-left: .25rem;
				}

				div#page-content-body .work_back-numbers               div.section-body section.chapter p.info em,
				div#page-content-body .work_back-numbers-with-synopsis div.section-body section p.info em,
				div#page-content-body .work_back-numbers-with-chapters div.section-body section p.info em {}

				div#page-content-body .work_back-numbers               div.section-body section.chapter p.info em,
				div#page-content-body .work_back-numbers-with-synopsis div.section-body section p.info em,
				div#page-content-body .work_back-numbers-with-chapters div.section-body section p.info em,
				div#page-content-body .work_back-numbers               div.section-body section.chapter p.info em *,
				div#page-content-body .work_back-numbers-with-synopsis div.section-body section p.info em *,
				div#page-content-body .work_back-numbers-with-chapters div.section-body section p.info em * {
					font-weight: bold;
				}

					div#page-content-body .work_back-numbers               div.section-body section.chapter p.info em:before,
					div#page-content-body .work_back-numbers-with-synopsis div.section-body section p.info em:before,
					div#page-content-body .work_back-numbers-with-chapters div.section-body section p.info em:before,
					div#page-content-body .work_back-numbers               div.section-body section.chapter p.info em:after,
					div#page-content-body .work_back-numbers-with-synopsis div.section-body section p.info em:after,
					div#page-content-body .work_back-numbers-with-chapters div.section-body section p.info em:after {
						position: relative;
						color: rgb(96,96,96);
					}

					div#page-content-body .work_back-numbers               div.section-body section.chapter p.info em:before,
					div#page-content-body .work_back-numbers-with-synopsis div.section-body section p.info em:before,
					div#page-content-body .work_back-numbers-with-chapters div.section-body section p.info em:before {
						content: "【";
						margin-right: .1rem;
					}

					div#page-content-body .work_back-numbers               div.section-body section.chapter p.info em:after,
					div#page-content-body .work_back-numbers-with-synopsis div.section-body section p.info em:after,
					div#page-content-body .work_back-numbers-with-chapters div.section-body section p.info em:after {
						content: "】";
						margin-left: .1rem;
					}

			div#page-content-body .work_back-numbers               div.section-body section.chapter div.pgroup,
			div#page-content-body .work_back-numbers-with-synopsis div.section-body section div.pgroup,
			div#page-content-body .work_back-numbers-with-chapters div.section-body section div.pgroup {
				padding-top: 0;
			}

			div#page-content-body .work_back-numbers               div.section-body section.chapter ul,
			div#page-content-body .work_back-numbers-with-synopsis div.section-body section ul,
			div#page-content-body .work_back-numbers-with-chapters div.section-body section ul {
				overflow: hidden;
				margin: 0;
				padding: 5px 0;
				border-top: solid 1px rgb(225,225,225);
				border-bottom: none 0;
		        border-radius: 0 0 8px 8px;
				background: rgb(246,246,246);
			}

				div#page-content-body .work_back-numbers               div.section-body section.chapter ul li,
				div#page-content-body .work_back-numbers-with-synopsis div.section-body section ul li,
				div#page-content-body .work_back-numbers-with-chapters div.section-body section ul li {
					list-style: none outside;
					position: relative;
					left: -1px;
					float: left;
					margin: 3px 0;
					padding: 0 8px;
					border-top: none 0;
					border-left: solid 1px rgb(225,225,225);
					line-height: 1.1;
					font-size: 1.3rem;
					text-align: center;
					background: transparent;
				}

					div#page-content-body .work_back-numbers               div.section-body section.chapter ul li a,
					div#page-content-body .work_back-numbers-with-synopsis div.section-body section ul li a,
					div#page-content-body .work_back-numbers-with-chapters div.section-body section ul li a {
						text-decoration: underline;
					}

					div#page-content-body .work_back-numbers               div.section-body section.chapter ul li span.disabled,
					div#page-content-body .work_back-numbers-with-synopsis div.section-body section ul li span.disabled,
					div#page-content-body .work_back-numbers-with-chapters div.section-body section ul li span.disabled {
						color: darkgray;
					}


/* //..	[instance] Work: Introduction: Charactors / section.work_characters
/* //..	[instance] Work: Introduction: Authors / section.work_authors
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

	div#page-content-body section.work_characters div.section-body,
	div#page-content-body section.work_authors div.section-body {
		padding-top: 15px;
	}

		div#page-content-body section.work_characters div.section-body section,
		div#page-content-body section.work_authors div.section-body section {
			position: relative;
			padding-left: 82px;
			min-height: 72px;
		}

			div#page-content-body section.work_characters div.section-body section h3,
			div#page-content-body section.work_authors div.section-body section h3 {
				padding-bottom: .25rem;
				border-bottom: solid 1px rgb(203,203,203);
			}

				div#page-content-body section.work_authors div.section-body section h3 em.author_part {
					color: rgb(255,153,0);
				}

				div#page-content-body section.work_authors div.section-body section h3 em.author_part:after {
					content: " : ";
				}

			div#page-content-body section.work_characters div.section-body section h4 {
				margin-bottom: .5rem;
				font-size: 1.4rem;
				font-weight: bold;
				font-style: oblique;
			}

			div#page-content-body section.work_characters div.section-body section figure,
			div#page-content-body section.work_characters div.section-body section figure img,
			div#page-content-body section.work_authors div.section-body section figure,
			div#page-content-body section.work_authors div.section-body section figure img {
				width: 72px;
				height: 72px;
			}

			div#page-content-body section.work_characters div.section-body section figure,
			div#page-content-body section.work_authors div.section-body section figure {
				position: absolute;
				left: 0;
				top: 0;
			}

			div#page-content-body section.work_authors div.section-body section ul {}
			div#page-content-body section.work_authors div.section-body section ul.subjoinder {
				margin: 0;
			}

			div#page-content-body section.work_authors div.section-body section p.subjoinder,
			div#page-content-body section.work_authors div.section-body section ul.subjoinder li {
				margin: .5em 0;
				font-size: 1.1rem;
			}

				div#page-content-body section.work_authors div.section-body section ul.subjoinder li {
					list-style-type: none;
				}

			div#page-content-body section.work_authors div.section-body section ul.author_twitter-info {
				overflow: hidden;
				margin: 10px 0 0;
			}

				div#page-content-body section.work_authors div.section-body section ul.author_twitter-info li {
					list-style-type: none;
					float: left;
					margin: 0;
					padding-right: 20px;
					height: 21px;
					line-height: 21px;

					@media(max-width: $w-breakpoint) {
						float: none;
						height: auto;
						margin-bottom: 15px;
					}
				}

						div#page-content-body section.work_authors div.section-body section ul.author_twitter-info li em img {
							vertical-align: text-bottom;
							width: auto;
						}

					div#page-content-body section.work_authors div.section-body section ul.author_twitter-info li a {
						font-size: 1.4rem;
						font-weight: bold;
						text-decoration: none;
						color: black;
						@media(max-width: $w-breakpoint) {
							display: block;
						}
					}


/* //..	[instance] Work: .work-feature
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

.work-feature {
	clear: both;
	text-align: center;
}
.work-feature__item {
	width: 100%;
	text-align: center;
	margin-bottom: 10px;
	@media(max-width: $w-breakpoint) {
		@mixin sp-base;
	}
}


/* //..	 Work: Samuraigirl
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

header#page-content-header.samuraigirl hgroup#page-content-heading {
	height: 548px;
	}
	header#page-content-header.samuraigirl hgroup#page-content-heading h2.cover-image {
		height: 495px;
		position: absolute;
		margin-top: 10px;
		}

header#page-content-header.samuraigirl .samurai-bnr {
	width: 210px;
	position: absolute;
	top: 80px;
	right: 8px;
	z-index: 40;
	padding: 5px 5px 2px;
    border-radius: 6px;
	}

	header#page-content-header.samuraigirl .samurai-bnr h3 {
		text-align: center;
		}
	header#page-content-header.samuraigirl .samurai-bnr ul {
		margin: 0;
		padding-top: 20px;
		}
		header#page-content-header.samuraigirl .samurai-bnr ul li {
			height: 55px;
			background: #ffffff;
			list-style: none;
			line-height: 0;
			margin-left: 0;
			margin-bottom: 5px;
			}
			header#page-content-header.samuraigirl .samurai-bnr ul li.comingsoon {
				background: none;
				margin-bottom: 4px;
				}
			header#page-content-header .samurai-bnr ul li a {
				display: inline-block;
				}
			header#page-content-header .samurai-bnr ul li a:link,
			header#page-content-header .samurai-bnr ul li a:active,
			header#page-content-header .samurai-bnr ul li a:visited {
				border: 1px solid #fffabe;
				}
			header#page-content-header .samurai-bnr ul li a:hover {
				border: 1px solid #ffffff;
				}


#samurai-movie {
	width: 100%;
	position: relative;
	height: 0;
	padding: 30px 0 56.25%;
	overflow: hidden;
	margin-bottom: 30px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

section.work_synopsis.samurai-synopsis div.section-body{
	padding-right: 0 !important;
	.pgroup {
		min-height: 316px;
		background: url(/content/samuraigirl/top/bg.png) no-repeat left bottom;
		padding-bottom: 15px;
	}
	.pgroup p {
		width: 400px;
		@media(max-width: $w-breakpoint) {
			width: 100%;
			padding-right: 15px;
			box-sizing: border-box;
		}
	}
	.pgroup strong {
		display: block;
		margin-bottom: .5rem;
	}
}

section.work_synopsis.samurai-synopsis section#samurai-chara {
	background: #fff100;
	margin-right: 15px;
	padding: 10px;
	}
	section.work_synopsis.samurai-synopsis section#samurai-chara h3 {
		margin-bottom: 5px;
		}

	section.work_synopsis.samurai-synopsis section#samurai-chara .section-body {
		padding: 0;
		border: none;
		}
		section.work_synopsis.samurai-synopsis section#samurai-chara .section-body .chara {
			background: #ffffff;
			padding: 10px;
			overflow: hidden;
			position: relative;
			border-radius: 6px;
			}
			section.work_synopsis.samurai-synopsis section#samurai-chara .section-body .chara figure {
				position: absolute;
				top: 10px;
				left: 10px;
				}
			section.work_synopsis.samurai-synopsis section#samurai-chara .section-body .chara h4 {
				font-weight: bold;
				font-size: 1.4rem;
				margin-bottom: .2rem;
				}
			section.work_synopsis.samurai-synopsis section#samurai-chara .section-body .chara h4,
			section.work_synopsis.samurai-synopsis section#samurai-chara .section-body .chara p {
				padding-left: 70px;
				}
			section.work_synopsis.samurai-synopsis section#samurai-chara .section-body #chara-hayato  {	
				margin-bottom: 10px;
			}
			section.work_synopsis.samurai-synopsis section#samurai-chara .section-body #chara-himari  {	
				margin-top: 10px;
				}


/* //..	 Work: Saezuriwaltz
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

.saezuri-download {
	width: 100%;
	margin-bottom: 20px;
	@media(max-width: $w-breakpoint) {
		@mixin sp-base;
	}

	.download-type {
		text-align: right;
		margin-top: -30px;
		margin-bottom: 30px;

		@media(max-width: $w-breakpoint) {
			text-align: center;
			margin: 0;
			padding: 15px 0;
		}
	}
	.download-type li {
		display: inline-block;
		margin-right: 10px;
	}
	.download-type li.kindle {
		background: url(/content/saezuriwaltz/icn_kindle.gif) no-repeat left center;
		padding-left: 14px;
		}
	.download-type li.kobo {
		background: url(/content/saezuriwaltz/icn_kobo.gif) no-repeat left center;
		padding-left: 14px;
		}
	.download-type li.iphone {
		background: url(/content/saezuriwaltz/icn_iphone.gif) no-repeat left center;
		padding-left: 14px;
		}
	.download-type li.ipad {
		background: url(/content/saezuriwaltz/icn_ipad.gif) no-repeat left center;
		padding-left: 20px;
		}
	.download-type li a {
		color: #000000 !important;
		&:visited {
			color: #B9010F;
		}
		&:hover {
			color: #FF9900 !important;
			text-decoration: underline !important;
		}
		&:active {
			color: #CC6600;
		}
	}
	.download-note {
		margin-bottom: 10px;
		li {
			font-size: 1.4rem;
			line-height: 1.4;
			margin-bottom: 1em;
		}
	}
}

/* //..	 Work: Gamekids2013
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

header#page-content-header.gamekids2013 {
	position: relative;
	}
	header#page-content-header.gamekids2013 div.sp_edition {
		width: 160px;
		height: 143px;
		background: url(/content/gamekids2013/top/bg_btn.png) no-repeat left top;
		position: absolute;
		top: 10px;
		left: 10px;
		z-index: 100;
		@media(max-width: $w-breakpoint) {
			@mixin sp-base;
			position: static;
			width: 100%;
			background: none;
		}
		h3 {
			display: none;
			@media(max-width: $w-breakpoint) {
				display: block;
				border-bottom: 2px solid #0e316c;
			}
		}
	}
		header#page-content-header.gamekids2013 div.sp_edition ul {
			padding-top: 22px;
			padding-left: 5px;
			@media(max-width: $w-breakpoint) {
				text-align: center;
				padding: 0;
			}
		}
		header#page-content-header.gamekids2013 div.sp_edition ul li {
			list-style: none;
			margin: 0 0 5px;
			line-height: 0;

			@media(max-width: $w-breakpoint) {
				display: inline-block;
			}
		}

		header#page-content-header.gamekids2013 div.sp_edition ul li:last-child {
			@media(max-width: $w-breakpoint) {
				display: none;
			}
		}
		header#page-content-header.gamekids2013 div.sp_edition ul li a {
			display: inline-block;
			margin-bottom: -5px;
			}

#page-content-body.gamekids2013 section.work_back-numbers div.section-body ul {
	overflow-y: scroll;
	height: 240px;
	}

/* //..	 Work: Osakashousei-den
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
#page-content-body #map .section-body {
	padding-right: 0;
	overflow: hidden;
}

#page-content-body #map .section-body ul li {
	list-style: none;
	width: 186px;
	height: 273px;
	float: left;
	margin-right: 10px;
	margin-left: 0;
	margin-bottom: 10px;
}

#page-content-body #map .section-body ul li.comingsoon {
	-ms-filter: "alpha( opacity=50 )";
	filter: alpha( opacity=50);
	opacity: 0.5;
}

/* //..	 Work: ichikata
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
#ichikata_movie {
	width: 630px;
	margin-right: -20px;
	overflow: hidden;
	margin-bottom: 15px;
	@media(max-width: $w-breakpoint) {
		@mixin sp-base;
		width: 100%;
	}
}
#ichikata_movie div {
	width: 295px;
	float: left;
	margin-right: 20px;
	@media(max-width: $w-breakpoint) {
		display: inline-block;
		width: 100%;
		float: none;
		text-align: center;
		margin-bottom: 10px;
	}
	iframe {
		@media(max-width: $w-breakpoint) {
			display: inline-block;
			width: 100%;
			min-height: 240px;
			margin: 0 auto 10px;
		}
	}
}

/* //..	 Work: tenohira
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
.tenohira-cover{
	position: relative;
}
.tenohira-cover_characterBotton{
	position: absolute;
	top: 253px;
	left: 16px;
	z-index: 2;
	@media(max-width: $w-breakpoint) {
		position: static;
		display: block;
		width: 60%;
		margin: 0 auto;
	}
}
.tenohira-cover_recommendBotton{
	position: absolute;
	top: 412px;
	left: 16px;
	z-index: 1;
	@media(max-width: $w-breakpoint) {
		position: static;
		display: block;
	}
}
.work_characters-tenohira .section-body {
	background : url(/content/tenohira/top/charaBg01.png) no-repeat left top;
	padding: 30px 30px 30px 0 !important;
	overflow: hidden;
	@media(max-width: $w-breakpoint) {
		background: none;
		padding: 0 !important;
		box-sizing: border-box;
	}
}
.tenohira-charaList {
	width: 424px;
	background : url(/content/tenohira/top/charaBg02.png) no-repeat left top;
	float: right;
	-webkit-border-radius: 4px;
	   -moz-border-radius: 4px;
	   border-radius: 4px;
	@media(max-width: $w-breakpoint) {
		width: 100%;
		background: none;
	}
}
.tenohira-chara {
	padding-bottom: 10px;
	margin: 20px 20px 0px;
	overflow: hidden;
}
.tenohira-chara:first-child {
	border-bottom: 2px dotted #541b84;
}
.tenohira-chara .chara-img {
	width: 72px;
	height: 72px;
	float: left;
	margin-right: 10px;
}
.tenohira-chara .chara-text {
	width: 302px;
	float: left;
	font-size: 14px;
	@media(max-width: $w-breakpoint) {
		width: 100%;
	}
}
.chara-text h3 {
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 5px;
}
.work_comment-tenohira .twitter_bird{
	vertical-align: middle;
	margin-right: 2px;
}


.work_comment-tenohira .section-body {
	background : url(/content/tenohira/top/commentBg01.png) repeat-y left top;
	padding: 20px;
	overflow: hidden;
   border-radius: 0 0 6px 6px;
	}
.work_comment-tenohira .comment-box {
	background : url(/content/tenohira/top/commentBg02.png) repeat  top;
	margin-bottom: 15px;
	padding: 15px 15px 0;
   border-radius: 4px;
	 }
.work_comment-tenohira .comment-box:last-child{
	margin-bottom: 0px;
}
.work_comment-tenohira .section-body {
	border: none !important;
}
p.comment-title {
	color: #551c87;
	font-weight: bold;
	font-size: 16px !important;
	margin-bottom: 15px !important;
}
.tenohira-comment {
	overflow: hidden;
	padding-bottom: 15px;
}
.comment-img {
	width: 72px;
	height: 72px;
	float: left;
	margin-right: 10px;
	@media(max-width: $w-breakpoint) {
		float: none;
		margin: 0 auto 10px;
	}
}
.comment-text {
	width: 466px;
	float: left;
	@media(max-width: $w-breakpoint) {
		float: none;
		width: 100%;
	}
}
.comment-text h3 {
	font-size: 14px;
	font-weight: bold;
	border-bottom: 1px solid #cacbcd;
	padding-bottom: 6px;
	margin-bottom: 10px;

	img {
		width: 14px;
		height: 14px;
	}
}
.comment-text h3 a {
	color: #626262;
	font-weight: normal;
	font-size: 12px;
	text-decoration: underline;
}
.comment-text .author_part {
	color: #ff9900;
}

/* //..	 Work: aschenputtel
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

.aschenputtel header#page-content-header nav.work_action-nav ul li {
	padding-right: 0;
}
.aschenputtel header#page-content-header nav.work_action-nav ul li.odd {
	padding-right: 10px;
}
.aschenputtel header#page-content-header section.work_new-episode hgroup h3 {
	line-height: 1.5;
}
div#page-content-body.aschenputtel .work_introduction div.section-body.no-border {
	border: none;
	padding: 0;
}
.aschenputtel .movie h3 {
	font-size: 20px;
	margin-top: 15px;
	margin-bottom: 15px;
}
	.aschenputtel .movie h3 span {
		display: block;
		font-size: 14px;
	}
.aschenputtel .work_introduction.calender div.section-body {
	background: url(/content/aschenputtel/bg_calender.jpg) repeat left center;
}
.aschenputtel .work_introduction.calender div.section-body div {
	text-align: center;
}
.aschenputtel .work_introduction.calender div.section-body .calendernovel-image {
	margin-top: 26px;
	margin-bottom: 20px;
}
.aschenputtel .work_introduction.calender div.section-body .follow-twitter {
	margin-bottom: 55px;
	width: 290px;
	margin: 0 auto;
}
.aschenputtel .work_introduction.calender div.section-body .follow-twitter p {
	font-size: 20px;
	font-weight: bold;
	text-align: center;
	line-height: 1;
	margin-bottom: 10px;
}


/* //..	[instance] Work: Introduction: Publications / section.work_publications
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

div#page-content-body section.work_publications div.section-body {
	background: rgb(255, 255, 255);
	background: rgba(255, 255, 255, 0.78);
	}
div#page-content-body section.work_publications div.section-body ul li.comment {
	background: rgb(255,255,255);
	background: rgba(255,255,255,0.88);
	border: 1px solid #ffffff;
	padding: 9px 0;
	margin: 0 9px;
	border-radius: 3px;
	@media(max-width: $w-breakpoint) {
		float: none;
		width: 100%;
		margin: 0 auto;
	}
}
div#page-content-body section.work_publications div.section-body ul li.comment a,
div#page-content-body section.work_publications div.section-body ul li.comment a:hover {
	background: none;
	border: none;
	border-radius: 0;
	}

div#page-content-body section.work_publications div.section-body ul li.comment a  {
	padding: 0 8px;
	}

div#page-content-body section.work_publications div.section-body ul li.comment p.item-comment {
	width: 150px;
	font-size: 1.2rem;
	line-height: 1.3;
	margin: 0 auto;
	@media(max-width: $w-breakpoint) {
		width: 100%;
		text-align: center;
	}
}
div#page-content-body section.work_publications div.section-body ul li.comment .item-image img {
	width: 150px;
}
div#page-content-body section.work_publications div.section-body ul li.comment p.item-comment span.comment-txt {
	width: 136px;
	display: inline-block;
	text-align: left;
	border: 2px solid #dcdcdc;
	margin: 10px auto 0;
	padding: 5px;
	border-radius: 6px;
	@media(max-width: $w-breakpoint) {
		width: 90%;
		box-sizing: border-box;
	}
}
div#page-content-body section.work_publications div.section-body ul li.comment p.item-comment span.comment-txt a {
	text-decoration: underline !important;
	color: #E60012;
	word-break: break-all;
	}

div#page-content-body section.work_publications div.section-body ul li.comment p.item-comment span.commentator {
	width: 150px;
	display: inline-block;
	text-align: center;
	font-weight: bold;
	padding-top: 12px;
	margin: 0 auto;
	}
	div#page-content-body section.work_publications div.section-body ul li.comment p.item-comment span.commentator img {
		vertical-align: bottom;
		width: 15px;
		height: 15px;
		padding-right: 4px;
		padding-left: 8px;
		}


div#page-content-body section.work_publications div.section-body ul li.comment a:hover p.item-comment,
div#page-content-body section.work_publications div.section-body ul li.comment a:hover p.commentator {
	color: #000000;
	}

/* //.	- work_introduction#related-works
---------------------------------------------------------------------------------------------------- */
div#page-content-body section.work_introduction {
	clear: both;
	margin-bottom:20px;
	}

	div#page-content-body section#related-works div.section-body {
		border:none;
		background: url("/sessions/res/img/line-related-works.gif") repeat-x 0 100%;
		padding:5px 0px 6px;
		overflow:hidden;
		position: relative;
	}
		 div#page-content-body section#related-works div.section-body section h3 {
			line-height: 1.3;
			padding-top:5px;
			}

		div#page-content-body section#related-works div.section-body section p {
			width:455px;
			float:left;
			font-size: 1.3rem;
			line-height:1.4;
			margin-top:-3px;
			@media(max-width: $w-breakpoint) {
				float: none;
				width: auto;
				margin-bottom: 10px;
			}
		}

		div#page-content-body section#related-works div.section-body section figure img {
			float:left;
			margin-right:10px;
			width:50px;
			height:50px;
			}

		div#page-content-body section#related-works div.section-body section a {
			display: block;
			position:absolute;
			top:10px;
			right:0px;
			width:90px;
			height:41px;
			@media(max-width: $w-breakpoint) {
				position: static;
				margin: 0 auto;
			}
		}

.work_introduction.movie {
	clear: both;
	.moviebox {
		width: 100%;
		position: relative;
		height: 0;
		padding: 30px 0 56.25%;
		overflow: hidden;

	  iframe {
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	  }
	}
}
