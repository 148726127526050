/* // -------------------------------------------------------------------------------------------------
// // div#page-content-body
---------------------------------------------------------------------------------------------------- */


/* //..	[class] .work-nav__itemlist
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

.work-nav__itemlist {
	font-size: 0;
	max-width: $w-base;
	width: 100%;
	font-size: 0;
	display: flex;
	flex-wrap: wrap;

	@media(max-width: $w-base) {
		padding: 0 15px;
		box-sizing: border-box;
	}
}
.work-nav__item {
	display: inline-block;
	border-bottom: 1px solid #cccccc;
	padding-bottom: 32px;
	position: relative;
	a {
		display: block;
	}
	img {
		width: 100%;
		height: auto;
	}
	@media(max-width: $w-breakpoint) {
		padding-bottom: 10px;
	}
}

.work-nav__image {
	margin-bottom: 8px;
	a {
		display: block;
		&:hover {
			@mixin hover-image;
		}
	}
}
.work-nav__title {
	margin-bottom: 8px;
	a {
		color: $fc-base;
		font-size: $fs-base;
		text-decoration: none;
		@mixin lineHeight 16,22;
		font-weight: bold;
		&:hover {
			color: $fc-hover;
			transition: 0.2s;
		}
	}
}
.work-nav__authorslist {
	margin-bottom: 8px;	
}
.work-nav__author {
	color: $fc-gray;
	font-size: 1.1rem;
	display: inline-block;
	padding-right: 1em;
}
.work-nav__desc {
	color: $fc-gray;
	font-size: 1.1rem;
	margin-bottom: 8px;
}
.work-nav__buttons {
	position: absolute;
	bottom: 8px;
	display: flex;
	@media(max-width: $w-breakpoint) {
		position: inherit;
		width: 100%;
	}
}
.work-nav__button {
	display: inline-block;
	width: 124px;
	text-align: center;
	font-size: 1.2rem;
	&:nth-child(1){
		margin-right: 8px;
	}
	a {
		text-decoration: none;
		color: $fc-white;
		border-radius: 4px;
		padding: 4px 0;
	}
	span {
		@media(min-width: $w-breakpoint) {
			@mixin arrow-right 5px , 1px, #fff;
			padding-left: 12px;
		}
		@media(max-width: $w-breakpoint) {
			@mixin arrow-right 6px , 1px, #fff;
			padding-left: 14px;
		}
	}

	@media(max-width: $w-breakpoint) {
		width: 50%;
		a {
			height: 30px;
			line-height: 30px;
		}
	}
}
	.work-nav__button--more a {
		background-color: $cl-orange;
		&:hover {
			background: color($cl-orange l(60%));
			transition: 0.2s;
		}
	}
	.work-nav__button--latest a {
		background-color: $cl-red;
		&:hover {
			background: color($cl-red l(60%));
			transition: 0.2s;
		}
	}

.work-nav__itemlist--col2 {
	.work-nav__item {
		@mixin col-st 2,2n;
		margin-bottom: 24px;

		@media(max-width: $w-breakpoint) {
			width: 100%;
			margin-right: 0;
			margin-bottom: 10px;
		}
	}
}

.work-nav__itemlist--col3 {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 64px;
	@media(max-width: $w-breakpoint) {
		margin-bottom: 45px;
	}

	.work-nav__item {
		@mixin col-st 3,3n;
		display: flex;
		flex-direction: column;
		align-content: flex-end;
		justify-content: space-between;
		min-height: 72px;
		margin-bottom: 8px;

		@media(max-width: $w-base) {
			&:nth-child(3n) {
				margin-right: calc(24 / 1080 *100)%;
			}
			@mixin col-st 2,2n;
		}
		@media(max-width: $w-breakpoint) {
			width: 100%;
			min-height: 84px;
		}
	}
	.work-nav__image {
		display: block;
		width: 72px;
		height: 72px;
		position: absolute;
		left: 0;
		top: 0;
		@media(max-width: $w-breakpoint) {
			width: 84px;
			height: 84px;
		}
	}
	.work-nav__text {
		height: 100%;
		padding-left: 88px;
		@media(max-width: $w-breakpoint) {
			padding-left: 100px;
		}
	}
}
.work-nav__row {
	display: flex;
	@media(max-width: $w-base) {
		@mixin sp-base;
	}
	@media(max-width: $w-breakpoint) {
		display: block;
	}
}

.work-nav__itemlist--col {
	display: inline-block;
	width: 50%;
	&:nth-child(odd) {
		margin-right: calc(24 / 1080 *100)%;
	}
	@media(max-width: $w-breakpoint) {
		width: 100%;
		&:nth-child(odd) {
			margin-right: 0;
		}
	}
	.content-header {
		padding: 0;
	}
	.work-nav__item {
		display: flex;
		flex-direction: column;
		align-content: flex-end;
		justify-content: space-between;
		min-height: 72px;
		padding-bottom: 8px;
		margin-bottom: 8px;
		position: relative;
		&:last-child {
			margin-bottom: 16px;
		}
		@media(max-width: $w-breakpoint) {
			min-height: 84px;
		}
	}
	.work-nav__image {
		display: block;
		width: 72px;
		height: 72px;
		padding-right: 16px;
		position: absolute;
		left: 0;
		top: 0;
		@media(max-width: $w-breakpoint) {
			width: 84px;
			height: 84px;
		}
	}
	.work-nav__text {
		padding-left: 88px;
		@media(max-width: $w-breakpoint) {
			padding-left: 100px;
		}
	}
	.work-nav__title {
		margin-bottom: 0;
	}
	.work-nav__lead {
		font-size: 1.4rem;
	}
}

/* //..	[class] Work: .work-front-page
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
.work-front-page {
	width: 100%;
	max-width: 610px;
	padding: 24px 0;
	margin: 0 auto;
	box-sizing: border-box;

	.cover-image {
		text-align: center;
		line-height: 0;
	}
	img {
		width: 100%;
	}
	@media(max-width: 1079px) {
		padding-top: 70px;
	}
}
